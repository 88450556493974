﻿@import '../../../../../Common/src/scss/components/react/ProductSpecifications.scss';

.ProductSpecifications {
    .btn.btn-primary {
        color: $light !important;
        border-color: $light !important;
    }
    .subheader {
        font-weight: inherit;
    }
    table {
        border-color: $light !important;
        > thead {
            > tr > th {
                &:not(:first-child) {
                    background-color: transparent !important;
                }
                &:first-child {
                    background-color: $dark !important;
                }
                color: var(--primary) !important;
                font-weight: bold !important;
            }
        }
        > tbody {
            > tr {
                > th {
                    color: $light !important;
                    background-color: $dark !important;
                    font-weight: bold !important;
                }
                > td {
                    color: $light !important;
                }
            }
        }
    }
}
