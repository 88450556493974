@import '../../../../../Common/src/scss/components/react/SearchTabbedFacet.scss';

.SearchTabbedFacet {
    .TabbedContent {
        &__tab {
            color: var(--primary);
            border-bottom-color: transparent;

            &.selected {
                border-color: var(--primary);
            }

            @media (hover: hover) {
                &:hover {
                    border-color: var(--primary);
                }
            }
        }
    }
}
