@import '../../../../Common/src/scss/blocks/_login.scss';

.login-block {
    @include make-container();

    &__content {
        @include make-row();
    }

    &__login,
    &__signup {
        > h5 {
            margin-bottom: $spacer * 2;
            color: var(--primary);
        }

        .btn {
            margin-top: $spacer * 1.5;
        }

        @include make-col-ready();
        @include make-col(6);

        @include media-breakpoint-down(xs) {
            @include make-col(12);
        }
    }

    &__login {
        @include media-breakpoint-up(sm) {
            border-right: 1px solid var(--primary);
            padding-right: calc(100% / 12);
        }

        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid var(--primary);
            padding-bottom: $spacer * 2;
        }
    }

    &__signup {
        @include media-breakpoint-up(sm) {
            padding-left: calc(100% / 12);
        }

        @include media-breakpoint-down(xs) {
            padding-top: $spacer * 2;
        }
    }

    &__signup-instructions {
        ol {
            list-style: none;
            counter-reset: li;
            padding-inline-start: 40px;

            > li {
                counter-increment: li;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            > li::before {
                display: inline-block;
                content: counter(li);
                background-color: var(--primary);
                color: $dark;
                font-size: 0.5em;
                line-height: 2em;
                min-width: 2em;
                min-height: 2em;
                border-radius: 1em;
                margin-left: calc(-40px + 0.25em);
                margin-right: 2em;
                text-align: center;
                direction: rtl;
            }
        }
    }

    &__register-button {
        @extend .btn, .btn-primary, .btn-lg, .h6;

        @include make-col-ready();
        @include make-col(12);
    }

    .login__form {
        .form-check {
            display: flex;
            align-items: center;
            padding-left: 0px;

            > label.form-check-label {
                color: $light;
            }
        }

        .btn[type='submit'] {
            @include make-col-ready();
            @include make-col(12);
        }
    }
}
