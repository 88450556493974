.StackContent {
    background: $gray-ghost;
    color: var(--dark);
    @extend .width__full-vw;
    padding-bottom: 91px;
    &__header {
        text-decoration: underline;
        text-decoration-color: $primary;
        text-decoration-thickness: 3px;
        font-weight: bolder;
        text-underline-offset: 3px;
        text-underline-position: under;
        padding: 15px 0px;
        margin: 8px 0px;
        letter-spacing: 1px;
        margin-top: 2em;
    }

    p {
        font-size: 1rem;
    }

    .doc-name {
        @include media-breakpoint-down(sm) {
            word-break: break-all;
        }
    }
}
