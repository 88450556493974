﻿@import '../../../../../Common/src/scss/components/react/CodePaymentMethod.scss';

.PromoCode,
.GiftCardGateway {
    display: flex;
    flex-direction: column-reverse;

    padding-top: 0 !important;
    .Field.w-75,
    .col-9 {
        width: 100% !important;
        flex: unset;
        max-width: unset;
    }
    .subheader {
        display: none;
    }
    .apply {
        position: relative;
        font-size: 0.875rem;
        padding: 0;

        &.container {
            max-width: none;
        }

        .row {
            margin: 0;
            width: 100%;
            padding: 0;
            position: relative;
        }

        .alert {
            position: relative;
            width: 100%;
            p {
                padding: 0;
                margin: 0;
                line-height: 1.33em;
            }
        }

        input {
            color: white;
            background: $primary-input-background-color;
            font-weight: bold;
            &::placeholder {
                color: white;
                font-weight: 300;
            }
            padding-right: 4rem !important;
            height: 3.5em;
            width: 100%;
        }

        .Field + button,
        input + button {
            position: absolute;
            padding: 0 0.4rem;
            right: 0.4rem;
            top: 0.4rem;
            height: calc(100% - 0.8rem);
            border: none !important;
            font-size: 0.857em;
            background-color: $dark;
            color: $light;
            letter-spacing: 1px;
            @media (hover: hover) {
                &:hover {
                    color: var(--primary);
                }
            }
        }

        .dropdown {
            border-color: color-mix(in srgb, var(--primary), transparent 80%);
            transition: 250ms border-color ease-in-out;

            @media (hover: hover) {
                &:not(:disabled):not([disabled]):not(.disabled):hover {
                    border-color: var(--primary);
                }
            }

            button {
                color: $light;
                font-weight: 300 !important;
                transition: 250ms color ease-in-out;

                @media (hover: hover) {
                    &:not(:disabled):not([disabled]):not(.disabled):hover {
                        color: var(--primary);
                    }
                }
            }

            .drawer {
                padding-bottom: 0.5em;
                @include branded-scroll-y();
            }
        }
    }

    .dropdown.mt-2 {
        margin-top: 0.75em !important;
    }
}
