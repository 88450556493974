.ItemDetailsCard {
    border: 1px solid var(--grid-odd-background);
    padding-bottom: 1rem;

    .attribute {
        &__header {
            text-decoration: underline;
            text-decoration-color: $primary;
            text-decoration-thickness: 3px;
            text-underline-offset: 0.5rem;
            font-weight: bolder;
            margin: 1rem 0 0.5rem 0;
            letter-spacing: 1px;

            @include media-breakpoint-down(sm) {
                text-align: left;
            }
        }

        &__value {
            font-size: 1rem;

            @include media-breakpoint-down(sm) {
                text-align: left;
            }
        }
    }

    &__details-container {
        margin-top: 2rem;
    }

    &__detail-row {
        display: flex;
        gap: 3;
    }

    &__detail-header {
        font-weight: bold;
        margin-right: 0.5rem;
    }

    &__title {
        font-weight: bolder;
        margin: 1rem 0 0.5rem 0;
        letter-spacing: 1px;
        text-align: center;
    }

    .ProductGallery {
        .slider-container {
            margin: 0 auto;
        }

        .slick-slider {
            .slick-list {
                overflow: hidden;
            }
        }

        .fit-image-nav,
        .fit-image-nav.video img,
        .fit-image-nav.spin img {
            height: 70px;
            width: 90px;
            object-fit: contain;
        }

        @include media-breakpoint-down(sm) {
            .slick-slider {
                .slick-list {
                    margin: 0 auto;

                    .slick-slide {
                        button {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }

            .fit-image-nav,
            .fit-image-nav.video img,
            .fit-image-nav.spin img {
                height: 70px;
                width: 90px;
            }
        }
    }
}
