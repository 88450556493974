﻿@import '../../../../../Common/src/scss/components/react/ProductListView.scss';

.ProductListView {

    .ProductBadge {
        .badge {
            font-family: var(--secondary-font);
            letter-spacing: 0.1em;
            font-weight: bold;
            font-size: 0.725rem;
            border: none;
            color: $orange-crush;
        }
    }

    .ProductPrice {
        p {
            color: var(--primary);
            font-family: var(--tertiary-font);
            font-weight: normal;
            letter-spacing: 0.1em;
        }

        .reduced-price {
            color: $red-smash;
        }
    }

    &__title {
        font-family: var(--primary-font);
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--primary);
        letter-spacing: 0.1em;
    }

    &__list {
        .ProductDetailCard {
            font-size: 0.725rem;

            &__name {
                h6 {
                    font-size: 0.975rem;
                    font-family: var(--primary-font);
                    letter-spacing: 0.15em;
                }
            }

            table {
                th,
                td {
                    font-size: 0.725rem;
                    letter-spacing: 0.1em;
                }
                th {
                    font-family: var(--secondary-font);
                    @include media-breakpoint-down(md) {
                        padding-top: 0.25rem;
                        vertical-align: top;
                    }
                }
                td {
                    font-family: var(--tertiary-font);
                    @include media-breakpoint-down(md) {
                        padding-top: 0.25rem;
                    }
                }
            }
        }
    }

    .ProductListTable {
        &.Grid {
            @include branded-scroll-x($dark, var(--primary), 0.75rem);

            .Grid {
                &__cell {
                    font-family: var(--tertiary-font);
                    font-size: 0.85rem;

                    &--header {
                        font-family: var(--secondary-font);
                        font-size: 0.725rem;
                        letter-spacing: 0.1em;
                        background-color: $gray-dark !important;
                    }

                    &--even {
                        &:not(.Grid__cell--header) {
                            background-color: $gray-dark;
                        }
                    }

                    &--odd {
                        &:not(.Grid__cell--header) {
                            background-color: $dark;
                        }
                    }

                    &:not(.Grid__cell--header) {
                        font-weight: 200;
                    }

                    .gallery-btn {
                        font-size: 1.25rem;
                    }

                    .NumberInput {
                        padding: 0.5em 3em !important;
                    }
                }
            }
        }
    }
}
