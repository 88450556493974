.LanguageSelector {
    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.dropdown-toggle {
            padding: 0.25em 0.5em;
            background: none;
            &::after {
                display: none;
            }
            &[aria-expanded='true'] {
                i.fa {
                    transform: scaleY(-1);
                }
            }
        }
        &:disabled {
            background: rgba(0, 0, 0, 0.2);
        }
    }
    margin-right: 0.25em;
}
