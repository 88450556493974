@import '../../../Common/src/scss/pages/_articlepage.scss';

.articlePage {
    & .breadcrumb {
        color: $light;
    }

    & .articlepage__protitle {
        color: $gray-ghost;
    }

    & .title {
        color: $light;
    }

    .articlepage__awards {
        color: $gray-ghost;
    }

    .main-p2 {
        color: $light;
        p {
            .wysiwyg-link {
                color: var(--primary) !important;
                text-decoration-color: var(--primary) !important;
            }
        }
    }

    .m-quote {
        color: $light;
    }

    .quote-box:before {
        color: var(--primary);
    }

    .quote-box h2 {
        color: $dark;
    }

    .quote-box p {
        color: $dark;
    }

    .big-banner .owl-carousel .owl-nav button.owl-next,
    .big-banner .owl-carousel .owl-nav button.owl-prev {
        color: $light;
        background: $dark;
    }

    .news-Card {
        border: 8px solid $dark;
        h6 {
            a {
                color: $dark;
            }
        }
    }

    .card-product {
        border: 1px solid $light;
        background-color: $dark;
    }

    .pro-heading {
        color: $light;
    }
}

.articleQuoteBlock {
    .quote-box h2 {
        color: $dark;
    }

    .quote-box p {
        color: $dark;
    }

    & .title {
        color: $dark;
    }

    & .description {
        color: $dark;
    }
}
