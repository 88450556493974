.breadcrumb {
    background: transparent;
    font-family: var(--tertiary-font);
    font-size: 0.75rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;

    @include media-breakpoint-down(md) {
        justify-content: start !important;
    }

    .breadcrumb-item {
        a {
            color: var(--primary);
            font-family: var(--tertiary-font);
            font-size: 0.75rem;
            line-height: 1.5rem;
            letter-spacing: 0.1rem;
        }

        &.active {
            color: var(--primary);
        }
    }
}
