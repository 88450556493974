@import '../../../../../Common/src/scss/components/react/InlineCart.scss';

.CartListing {
    &__total {
        color: var(--primary);
        font-family: var(--secondary-font);
        font-weight: bold;
        letter-spacing: 1px;
        border-top: 1px solid color-mix(in srgb, var(--primary), transparent 80%);

        .price {
            font-size: 1.2em;
            .amount {
                &.strike {
                    font-weight: 300;
                }
            }
        }

        .info {
            font-size: 0.875em;
            font-weight: 300;
        }
    }

    &__footer {
        color: var(--primary);
    }

    &__checkout + .CartItemList {
        margin-top: 1rem;
        border-top: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
    }
}

.CartListing,
.VariantSelector {
    background: $gray-dark;

    .header {
        h6 {
            color: var(--primary);
            font-family: var(--primary-font);
            font-size: 2.5em;
            font-weight: bold;
            letter-spacing: 0.0375em;
        }

        .btn-close {
            color: var(--primary);
        }
    }
}

.CartItem {
    &:not(:first-child) {
        border-top: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
    }

    // &__alert {
    //     margin-left: -6.286em;
    // }

    .ProductBadge {
        .badge {
            font-family: var(--secondary-font);
            letter-spacing: 1px;
            font-weight: bold;
            font-size: 0.625rem;
            border: none;
            color: $orange-crush;
            &:first-child {
                padding-left: 0;
                padding-bottom: 0;
            }
        }
    }

    &__name {
        color: var(--primary);
        -webkit-text-fill-color: var(--primary);
        font-family: var(--secondary-font);
        font-weight: bold;
        letter-spacing: 0.0625em;

        &:not([disabled]) {
            @media (hover: hover) {
                &:hover {
                    color: $light;
                }
            }
        }

        &.disabled {
            opacity: 1 !important;
        }
    }

    &__col.left {
        p {
            color: var(--primary);
            font-family: var(--secondary-font);
            font-weight: 300;
            font-size: 0.75em;
            background: $dark;
        }
    }

    .btn-del {
        color: var(--primary);

        @media (hover: hover) {
            &:hover {
                color: $light;
            }
        }
    }

    &__price {
        .strike {
            font-weight: 300;
        }
        p {
            color: var(--primary);
            font-family: var(--secondary-font);
            font-size: 0.875em;
            letter-spacing: 0.02em;
        }
        .reduced-price {
            color: red;
        }
    }

    &__additional-info {
        display: flex;
        justify-content: flex-end;
        p {
            color: var(--primary);
            font-family: var(--secondary-font);
            font-size: 0.875em;
            letter-spacing: 0.07em;
            padding-top: 1em;
        }
    }

    &__table {
        margin-top: 0.5em;
        th,
        td {
            color: var(--primary);
            font-family: var(--secondary-font);
            font-size: 0.875em;
            font-weight: 300;
            letter-spacing: 0.07em;
            line-height: 1.5em;
        }
    }

    .ProductGallery {
        &.disabled {
            pointer-events: none;
        }
    }
}
