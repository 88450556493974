@import '../../../../../Common/src/scss/components/react/CustomerPaymentManager.scss';

.CustomerPaymentManager {
    @include dark-bg-default-styles;

    .PaymentBlockIcon {
        color: $light;
    }

    .Modal {
        .modal-header {
            i {
                color: $light !important;
            }
        }
    }
}
