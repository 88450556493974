@import '../../../../Common/src/scss/blocks/_socialbannerblock.scss';

.socialbannerblock {
    .title-text-lg {
        font-family: var(--primary-font);
        font-weight: bold;
        font-size: 2rem;
        line-height: $line-height-base;
        text-transform: uppercase;
    }
    .social__link {
        .social__icon {
            color: var(--primary);
        }
    }
}
