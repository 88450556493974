@import '../../../../../Common/src/scss/components/react/HeaderSearch.scss';

.HeaderSearch {
    background: $gray-dark;
    top: 0px;
    padding-top: 110px;

    .SearchBar {
        border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
    }

    &__drawer {
        background: $gray-dark;

        .SearchDrawerLinks {
            background-color: transparent;
            .Stack__divider {
                border-color: $light;
            }

            &__links,
            &__recents {
                &--title {
                    color: $light;
                }

                &--link,
                &--href {
                    color: $light;
                }
            }

            &__links {
                &--title {
                    font-family: var(--tertiary-font);
                }
            }

            &.theme-dark {
                .Stack__divider {
                    border-color: color-mix(in srgb, var(--primary), transparent 80%);
                }

                .PanelSection {
                    &__body {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .SearchDrawerLinks__links,
                .SearchDrawerLinks__recents {
                    &--title {
                        color: $light;
                        width: 100%;
                    }

                    &--link,
                    &--href {
                        color: $light;
                    }
                }
            }
        }
    }

    &.theme-dark {
        background: $dark;

        .HeaderSearch__drawer--link a {
            color: var(--primary);
        }
    }
}
