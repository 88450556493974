@import '../../../../Common/src/scss/blocks/_shopbycarousel.scss';

.shopby_carousel {
    &__panels {
        &__panel {
            &__slide {
                &__shopbtn {
                    padding: 0.75rem;
                }
            }
        }
    }

    .SvgIcon {
        color: var(--primary);
    }
}
