@import '../../../../Common/src/scss/components/_forms.scss';

select:-webkit-autofill,
input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    transition: background-color 50000s ease-in-out 0s;
}
input.form-control {
    background-color: $primary-input-background-color !important;
}
