﻿@import '../../../../../Common/src/scss/components/react/CartItemListing.scss';

.CartItemListing {
    .toolbar .btn {
        span {
            transition: color 250ms;
        }

        &.btn-link {
            color: $light;
            text-decoration: none !important;
        }
        @media (hover: hover) {
            &:hover > span {
                color: $dark;
            }
            &.btn-link:hover > span {
                color: var(--primary);
            }
        }
    }

    .CatalogQuickView {
        h6 {
            padding-top: 0 !important;
            .ProductPrice {
                margin-top: 1rem;
            }
        }

        .btn-close {
            font-size: 1.5em;
            @include media-breakpoint-up(md) {
                right: 0.75rem;
            }
        }
    }

    .btn.clear-selection {
        color: $light;
        i {
            margin-right: 0.5em !important;
        }
    }

    .submit-btns button.disabled {
        opacity: 0.33;
    }

    .NumberInput {
        background: $gray-dark;
    }

    .DetailsLinks {
        display: none !important;
    }
}
