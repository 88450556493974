﻿@import '../../../../../Common/src/scss/components/react/ProductSearchBar.scss';

.ProductSearchBar {
    background: $gray-dark;

    &__bar {
        border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
        background-color: $primary-input-background-color;
    }

    &__icon {
        color: $white;
        font-size: 1rem;

        @media (hover: hover) {
            &:hover {
                color: $white;
            }
        }
    }

    .ProductSearchBarDrawer {
        background-color: $gray-dark;

        &__results {
            &__links {
                a {
                    font-size: 0.875rem;
                    letter-spacing: 0.14285em;
                    line-height: 1.357em;
                    color: var(--primary);
                }
            }

            &__result {
                color: var(--primary);
                font-weight: bold;

                &__image {
                    border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                }

                &__title {
                    font-size: 1rem;
                    text-transform: uppercase;
                    letter-spacing: 0.0625em;
                    line-height: 1.125em;
                }

                &__price {
                    > p {
                        font-size: 0.7368em;
                    }
                    .strike {
                        color: $red;
                    }

                    .ProductPrice__reduced-price {
                        color: var(--primary);
                    }
                }
            }
        }

        &__links {
            &__link {
                &__title {
                    text-transform: uppercase;
                    padding-bottom: 1.1785em;
                    margin-bottom: 1.1785em;
                    border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                    letter-spacing: 0.1314em;
                }

                a,
                p {
                    font-size: 0.7291em;
                    color: $white;
                    letter-spacing: 1px;

                    .Icon {
                        font-size: 1.1428em;
                    }
                }
            }
        }
    }
}
