﻿a.wysiwyg-link {
    /* Some links from Epi's WYSIWYG editor are wrapped in
       a <u /> tag. Rather than re-styling the nested tag,
       remove all styles and have it inherit, so we can
       don't need to duplicate styling rules. */
    color: var(--primary);

    &,
    &:hover,
    &:visited,
    &:active,
    &:focus {
        color: $white;
        text-decoration-color: $white;
    }

    u {
        text-decoration: none;
        font-weight: inherit;
    }

    &.light {
        color: $dark;
        text-decoration-color: $dark;
    }

    &.dark {
        color: var(--primary);
        text-decoration-color: $light;
    }
}

.a-sm {
    @include font-size(14px);
    letter-spacing: 2px;
}

.a-lg {
    @include font-size(19px);
    letter-spacing: 2.29px;
}

a.bold {
    font-weight: 900;
}

.a-dark {
    color: var(--primary);

    &:hover {
        color: $light;
        text-decoration-color: $light;
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        text-decoration-color: var(--primary);
        color: var(--primary);
    }

    &:active {
        text-decoration: none;
        color: $light;
    }
}

.a-light {
    color: $dark;

    &:hover {
        color: $dark;
        text-decoration-color: $dark;
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        text-decoration-color: $dark;
        color: $dark;
    }

    &:active {
        text-decoration: none;
        color: $dark;
    }
}

//defeault link
a {
    cursor: pointer;
    color: var(--primary);
    font-family: var(--secondary-font);
    @include font-size(16px);
    letter-spacing: 2.71px;

    &:hover {
        color: $light;
        text-decoration: underline;
        text-decoration-color: $light;
    }

    &:active {
        color: $light;
        text-decoration: none;
    }

    &:focus {
        color: var(--primary);
        text-decoration: underline;
        text-decoration-color: var(--primary);
    }
}
