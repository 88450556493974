﻿@import '../../../../../Common/src/scss/components/react/ProductInput.scss';

.ProductInput {
    color: var(--primary);
    height: 2.5em;

    .oos {
        .btn:not(.NotificationSubscription__notify-link) {
            background: $gray-tertiary !important;
            color: $light !important;
            opacity: 1 !important;
            pointer-events: none;
            border: none !important;
            height: 100%;
        }
    }

    > div {
        height: 100%;
    }

    .btn.btn-primary {
        height: 100%;
        &[disabled],
        &:disabled,
        &.disabled,
        &.buy-now:disabled {
            border: 1px solid var(--primary);
            color: $gray-medium !important;
        }
    }

    .num-input {
        display: flex;
        flex-direction: row;
        align-items: stetch;
        position: relative;
        font-size: 16px !important;
        @include media-breakpoint-down(sm) {
            font-size: 14px !important;
        }
    }

    .NumberInput-btn {
        border-top: 2px solid $primary-opaque;
        border-bottom: 2px solid $primary-opaque;
        opacity: 0.4 !important;
    }
}
