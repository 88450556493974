﻿@import '../../../../../Common/src/scss/components/react/OrderDetail.scss';

.OrderDetail {
    .OrderDetailHead {
        h5 {
            font-size: 1.8rem;
        }

        @include media-breakpoint-down(md) {
            h5 {
                font-size: 1rem;
            }
        }
    }

    .OrderShipmentLabel {
        font-size: 2rem;
    }

    .OrderDetailTableLabel > span {
        font-family: var(--primary-font) !important;
    }
}
