@import '../../../../../Common/src/scss/components/react/ProductAccordion.scss';

.ProductAccordion {
    ul {
        li {
            a {
                font-family: var(--primary-font);
                letter-spacing: 0.14em;
                font-weight: bold;
            }
        }
    }
}
