﻿@import '../../../../Common/src/scss/blocks/_orderdetaillookupblock.scss';
.order__lookup {
    padding-top: 20px;
    padding-bottom: 20px;

    .not-found {
        padding: 20px;
    }

    form {
        width: 100%;
    }

    label.subheader {
        height: 1.5rem;
        line-height: 1.4rem;
        letter-spacing: $letter-spacing;
        text-align: left;
        color: $light;
        display: block;
        padding-bottom: 2rem !important;
    }

    input.email,
    input.orderNumber {
        color: $light !important;
        border-color: $primary-opaque;
        background-color: rgba(237, 237, 237, 0.1);
        width: 95%;
        height: $btn-height;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $gray-secondary;
            opacity: 1; /* Firefox */
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .OrderDetail {
        animation: fadeIn 0.5s ease-out;
    }

    @include media-breakpoint-down('sm') {
        form {
            div {
                margin-bottom: 8px;
            }
        }

        button[type='submit'] {
            line-height: 16px;
        }

        & label {
            margin-bottom: 0.5rem;
        }
    }
}
