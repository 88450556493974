﻿@import '../../../../Common/src/scss/sections/_header.scss';

$navItemVerticalPadding: 0.45rem;

.header__section {
    position: relative;
    z-index: 9;

    a {
        position: relative;
    }

    .icons.icon-logo {
        font-size: 4rem;
        @include media-breakpoint-up(lg) {
            font-size: 5rem;
        }
    }

    .header__logo {
        z-index: 3;
        position: absolute;
        height: 6em;
        @include media-breakpoint-down(md) {
            min-height: 5em;
        }
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        > a {
            pointer-events: auto;
        }
    }
    .header__mast {
        z-index: 1;
        position: relative;
        min-height: 6em;
        @include media-breakpoint-down(md) {
            min-height: 5em;
        }
        .header-image {
            > img {
                max-height: 100vh;
                min-width: 100vw;
                min-height: 40vh;
                max-width: 100vw;
                text-align: center;
                object-fit: cover;
                object-position: left top;
            }
        }
        .header-button {
            text-decoration: none !important;
            display: flex;
            position: absolute;
            top: 6em;
            left: 1em;
            right: 1em;
            bottom: 1em;
            overflow: visible;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            z-index: 6;
            @include media-breakpoint-down(md) {
                top: 5em;
            }
            &.h-left {
                justify-content: flex-start;
            }
            &.h-right {
                justify-content: flex-end;
            }
            &.v-top {
                align-items: flex-start;
            }
            &.v-bottom {
                align-items: flex-end;
            }
            a.btn {
                line-height: 1em;
                font-size: 1.5vw;
                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                }
            }
        }
        .header-content {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 5;
            h1 {
                @include font-size(15vh);
                @include media-breakpoint-down(lg) {
                    @include font-size(6rem);
                }
                @include media-breakpoint-down(sm) {
                    @include font-size(4rem);
                }
                line-height: 1em;
                &.offset-left {
                    margin-left: 2em;
                }
                &.offset-right {
                    margin-right: 2em;
                }
                &.outline {
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: currentColor;
                }
            }
        }
    }
}

.header__user {
    .dropdown-menu {
        background-color: $gray-dark;
        color: $light;
        padding: $navItemVerticalPadding 0;
        border: 1px solid $light;

        .nav-link {
            background-color: initial;
            color: $light;
            font-size: 1rem;
            /* Default styling is uppercase. */
            text-transform: initial;
            padding-top: $navItemVerticalPadding;
            padding-bottom: $navItemVerticalPadding;
        }

        hr {
            background-color: $light;
            margin-top: $navItemVerticalPadding;
            margin-bottom: $navItemVerticalPadding;
        }

        .nav-item {
            color: var(--primary);

            .nav-link {
                background-color: initial;
                color: var(--primary);
                text-decoration: none;
            }

            @media (hover: hover) {
                &:hover .nav-link {
                    color: $light;
                    text-decoration: underline;
                    font-weight: bold;
                }
            }
        }
    }
}

@media (hover: hover) {
    .header__user.dropdown:hover .dropdown-menu:not(.show) {
        display: none;
    }
}
