.contentaccordionblock {
    .btn-machpp {
        font-family: var(--primary-font);
        font-weight: bold;
        font-size: 0.75rem;
        width: 100%;
        height: 2.25rem;
        border: none;
        border-top: 0.5px solid var(--primary);
        background-color: $gray-primary;
        color: var(--primary) !important;
        text-align: left;
        display: block;

        .fa-chevron-down,
        .fa-chevron-up {
            position: absolute;
            right: 1.5rem;
        }

        &.collapsed {
            .fa-chevron-up {
                visibility: hidden;
            }
            .fa-chevron-down {
                visibility: visible;
            }
        }

        &:not(.collapsed) {
            .fa-chevron-up {
                visibility: visible;
            }
            .fa-chevron-down {
                visibility: hidden;
            }
        }
    }
}
