@import '../../../../Common/src/scss/blocks/_contentcardshopblock.scss';

.content_card_shop {
    &__btn-container {
        a.btn {
            &.btn-outline-primary {
                @include button-outline-variant(var(--primary), var(--primary), rgba(0, 0, 0, 0.75), white);
                background-color: rgba(0, 0, 0, 0.75);
            }

            &.btn-outline-secondary {
                @include button-outline-variant(var(--secondary), var(--secondary), rgba(0, 0, 0, 0.75), white);
                background-color: rgba(0, 0, 0, 0.75);
            }
        }
    }
}
