﻿@import '../../../Common/src/scss/blocks/_article.scss';

.article {
    &__cardrow {
        .slick {
            &-prev:before,
            &-next:before {
                color: $light;
            }
        }
        .background__color-primary & {
            & .slick-next:before,
            & .slick-prev:before {
                color: var(--secondary);
            }
        }
        & .slick-next:before,
        & .slick-prev:before {
            color: var(--primary);
        }

        .card {
            & .social i {
                color: $dark;
            }

            & .card__back {
                background-color: $gray-secondary;

                & .card__text {
                    color: $gray-secondary;
                }

                & .card__action-close i {
                    color: $gray-secondary;
                }
            }
        }
    }

    &__cardgrid {
        & .search {
            & input.form-control[type='text'] {
                background-color: $dark !important;
            }
        }

        & .sort,
        .filter {
            & .btn {
                background-color: $dark;
                color: var(--primary);
            }
        }

        & .title {
            color: $light;
        }

        .card {
            background-color: $gray-secondary;

            & .card__back {
                background-color: $gray-secondary;

                & .card__text {
                    color: $gray-secondary;
                }

                & .card__action-close i {
                    color: $gray-secondary;
                }
            }
        }
    }
}
