@import '../../../../../Common/src/scss/components/react/SearchFacetBreadcrumbs.scss';

.SearchFacetBreadcrumbs {
    &__Title,
    &__Breadcrumb,
    &__ClearAll {
        color: var(--primary);
    }

    @media (hover: hover) {
        &__ClearAll:hover,
        &__Breadcrumb:hover {
            color: $light;
        }
    }
}
