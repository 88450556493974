@import '../../../../../Common/src/scss/components/react/StoreLocator.scss';

.StoreLocator {
    input.storeLocatorSearchInput.pac-target-input {
        color: $dark;
        background-color: var(--light);
        border: 2px solid #000;

        @include media-breakpoint-down('md') {
            width: 26em;
        }

        &::placeholder {
            color: rgba($dark, 0.5);
        }
    }


    .LocationResult {
        color: $gray-dark;
    }

    a {
        color: $dark;

        @media (hover: hover) {
            &:hover {
                color: var(--primary);
            }
        }
    }

    .LocatorList__filters {
        @include media-breakpoint-down(md) {
            > div:not(.LocatorList__filters__current-location) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        /* Deselected distance buttons look orange without this.
         * Overriding secondary button styling rather than changing
         * class because every other site uses the actual secondary
         * button styling.
         **/
        &__radius-select__button--inactive {
            @include button-outline-variant($primary);
            background: $dark;
        }
    }

    &__map .LocatorMap .ResearchBar > .alert {
        @include button-outline-variant($primary);
        background: $dark;
    }
}
