﻿@import '../../../../../Common/src/scss/components/react/DropdownSelector.scss';

.DropdownSelector {
    &__header {
        color: $light !important;
    }

    &__toggle {
        color: $light !important;

        &:hover {
            color: var(--primary);
        }
    }

    button p {
        font-weight: 500 !important;
        color: $light;
    }

    .btn-group {
        button {
            background: $gray-dark !important;
            border: 2px solid $primary-opaque !important;
            color: $light !important;
            height: 2.625rem;
            margin: 0 !important;
            min-width: 6.5rem;
            opacity: unset !important;
        }

        @include media-breakpoint-up(lg) {
            button:hover {
                border: 2px solid $green !important;
            }
        }

        button.selected {
            border: 2px solid $green !important;
        }

        button.disabled,
        button.oos {
            background: linear-gradient(
                to bottom right,
                transparent calc(47% - 1px),
                $primary-opaque,
                transparent calc(53% + 1px)
            ) !important;
        }

        button.selected.oos,
        button.oos:hover {
            background: linear-gradient(
                to bottom right,
                transparent calc(47% - 1px),
                $green,
                transparent calc(53% + 1px)
            ) !important;
        }
    }
}
