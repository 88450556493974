@import '../../../../Common/src/scss/blocks/_notificationsubscriptionblock.scss';

.notificationsubscriptionblock {
    .modal-header,
    .modal-body {
        color: $dark;
        background: var(--primary);
    }

    .modal-footer {
        background: $gray-dark;
    }
}
