@import '../../../../../Common/src/scss/components/react/SearchFiltersMenu.scss';

.SearchFiltersMenu {
    background-color: $gray-dark;
    color: var(--primary);

    &__Open {
        color: var(--primary);

        svg.Icon {
            color: var(--primary);
            stroke: var(--primary);
        }

        @media (hover: hover) {
            &:hover {
                color: $primary-opaque;
            }
        }
    }

    &__Header {
        &__Close {
            i.Icon {
                color: var(--primary);
                stroke: var(--primary);
            }
        }

        h3 {
            font-size: 2.5rem !important;
        }
    }

    .Stack {
        &__divider {
            border-top-color: var(--primary) !important;

            &--direction-row {
                border-left-color: var(--primary) !important;
            }
        }
    }

    .Dropdown__drawer {
        input[type='checkbox'] {
            border: 2px solid var(--primary) !important;

            &::before {
                background: var(--primary);
            }
        }

        .form-check-label {
            font-size: 1rem !important;
        }
    }
}
