.alert {
    color: $light;
    font-family: var(--secondary-font);
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5em;
}

.alert.alert-info {
    color: $info;
    border-color: $info;
}

.alert.alert-success {
    border-color: $success;
}

.alert.alert-warning,
.alert.alert-warn {
    border-color: $warning;
}

.alert.alert-danger {
    border-color: $danger;
    background-color: $red;
}
