﻿.footer {
    background: $gray-dark;
    padding: 3rem 3rem 0 3rem;

    @include media-breakpoint-down(md) {
        padding: 2rem 1rem 0 1rem;
    }

    &__copyright {
        background: $dark;
        color: $light;
        font-family: var(--secondary-font);
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.072em;
        line-height: 2.167em;
        padding: 1rem 8rem;
        margin: 9rem -9rem 0 -9rem;

        > div {
            flex-direction: row;
        }

        .separator {
            margin: 0 1rem 0 1.1rem;
        }

        @include media-breakpoint-down(md) {
            margin: 4rem -3rem 0 -3rem;
            padding: 2rem 0;

            > div {
                flex-direction: column;
            }
        }

        a {
            color: $light;
            font-family: var(--secondary-font);
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.072em;
            line-height: 2.167em;
        }
    }

    &__content {
        &__brand {
            align-items: center;

            @include media-breakpoint-up(lg) {
                align-items: flex-start;
                .address {
                    margin-top: 2.5rem;
                }
                p {
                    text-align: left;
                }
            }

            @include media-breakpoint-down(md) {
                p {
                    text-align: center;
                }
            }

            > div {
                margin-top: 1.5rem;
            }

            .logo:not(.mobile-logo) {
                font-size: 3rem;
            }
            .logo.mobile-logo {
                font-size: 5rem;
            }

            p {
                padding: 0;
                margin: 0;
                color: $light;
                font-family: var(--secondary-font);
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 20px;
            }

            .social {
                a {
                    margin: 0 0.67em;
                    i {
                        font-size: 1.33rem !important;
                    }
                }
            }
        }

        &__nav-section {
            @include media-breakpoint-down(md) {
                margin-top: 2.5rem;
                &.expanded {
                    .nav-category .closed-icon {
                        display: none;
                    }
                }
                &:not(.expanded) {
                    .nav-category .opened-icon {
                        display: none;
                    }
                    .nav-list {
                        max-height: 0;
                    }
                }
            }

            .nav-link {
                padding: 0;
                margin: 0;
            }

            .nav-list {
                transition: max-height 0.3s;
                max-height: none;
                overflow-y: hidden;
                overflow-x: wrap;
                margin-top: 1rem;

                ul {
                    font-size: 16px;
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    > li {
                        font-size: inherit;
                        padding: 0.5em 0;
                        > .nav-link {
                            font-size: inherit;
                            color: $light;
                            font-family: var(--secondary-font);
                            font-weight: 300;
                            letter-spacing: 0.0625em;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .icon-tiktokicon {
        height: 30px;
        margin-left: -10px;
        margin-right: -10px;
        width: 30px;
    }
}
