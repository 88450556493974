﻿@import '../../../../../Common/src/scss/components/react/ProductGridCell.scss';

.ProductGridCell {
    &__input {
        .num-input {
            max-height: 100%;
        }
        &.oos {
            .btn:not(.NotificationSubscription__notify-link) {
                background: $gray-tertiary !important;
                color: $light !important;
                opacity: 1 !important;
                pointer-events: none;
                border: none !important;
            }
        }
        .NumberInput {
            font-size: 14px !important;
            button,
            input {
                font-size: 14px !important;
            }
        }
    }
    &__name,
    &__price > * {
        font-size: 19px !important;
        @include media-breakpoint-down(sm) {
            font-size: 16px !important;
        }
        font-family: var(--secondary-font);
        font-weight: 600;
        letter-spacing: 0.13em;
        line-height: 1em;
    }
    &__top-bar {
        > .ProductBadge .badge {
            font-size: 0.8rem;
        }
    }

    .ProductGallery__Modal {
        .btn {
            &.left,
            &.right {
                color: $white;
            }
        }
    }
}
