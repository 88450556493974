﻿@import '../../../../../Common/src/scss/components/react/RMARequest.scss';

.RMARequest {
    .order-detail-head {
        h5 {
            font-size: 1.8rem;
            color: $light;
        }

        @include media-breakpoint-down(md) {
            h5 {
                font-size: 1.15rem;
                color: $light;
            }
        }
    }

    .RMARequestTable {
        .table {
            .tablehead {
                div > span {
                    font-family: var(--primary-font) !important;
                    font-size: 0.75rem;
                }
            }
        }
    }

    .ReasonCodeDropdown {
        button {
            border: 2px solid rgba(205, 248, 76, 0.2) !important;
            color: $light !important;
        }

        button:focus {
            border-color: var(--primary) !important;
        }
    }

    .dropdown-menu {
        border: 2px solid $primary-opaque;
        outline: unset;

        .reason {
            background-color: $dark !important;
            color: var(--primary) !important;
        }
    }

    .customer-notes {
        textarea {
            color: $light !important;
            border-color: $primary-opaque;
            background-color: rgba(237, 237, 237, 0.1);
            width: 100%;
            height: 9rem;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
