﻿@import '../../../Common/src/scss/components/_inputs.scss';

input:not([type='checkbox']):not([type='radio']):not([type='submit']),
select {
    background-color: $primary-input-background-color;
    border: 2px solid rgba(205, 248, 76, 0.2);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 20px;
    color: $light;
    font-family: var(--tertiary-font);

    &::placeholder {
        color: rgba($light, 0.5);
        font-family: var(--tertiary-font);
    }

    &:focus,
    &:focus-visible {
        border-width: 2px;
        border-color: var(--primary);

        &.invalid {
            border-color: $danger;
        }
    }

    &.invalid,
    &[aria-invalid='true'] {
        border-width: 2px;
        border-color: rgba($danger, 0.2);
        color: $danger;

        &::placeholder {
            color: rgba($danger, 0.5);
        }
    }
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='number']) {
    padding: 1rem 1rem;
}

select > option {
    background: $dark;
    color: var(--primary);
}

select,
.Field > select {
    padding: calc(1rem - 1px) 1rem;
    height: auto !important;
}

fieldset .Field {
    margin-top: 0.75em;

    &.inline > label {
        display: flex;
    }
    .ErrorDisplay {
        color: $danger;
        font-family: var(--tertiary-font);
        font-weight: 300;
        @include font-size(12px);
        margin-top: 0.5em;
    }
    &.invalid input:not([type='checkbox']):not([type='radio']):not([type='submit']),
    &.invalid select {
        border-color: rgba($danger, 0.2);
        color: $danger;
        &::placeholder {
            color: $danger;
        }
    }

    input:disabled,
    input.disabled,
    input[disabled],
    select:disabled,
    select.disabled,
    select[disabled] {
        opacity: 0.33 !important;
    }
}

input[type='checkbox'],
input[type='radio'],
div.input-checkbox,
div.input-radio {
    background-color: $primary-input-background-color;
    border-color: color-mix(in srgb, var(--primary), transparent 80%);
}

label {
    margin-bottom: 0px;
}

.NumberInput {
    box-sizing: border-box;
    background: $primary-input-background-color;
    margin: 0;
    width: 100%;
    font-size: inherit !important;
    &.buttons {
        padding: 0 1.4rem !important;
    }
    text-align: center;
    &.disabled,
    &:disabled,
    &[disabled] {
        background: none;
        color: $gray-medium !important;
        pointer-events: none;
    }
}

input + .NumberInput-btn {
    right: 0;
    left: auto;
}

.NumberInput-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    height: auto;
    font-size: 0.8em;
    color: $light !important;
    &.disabled,
    &:disabled,
    &[disabled] {
        opacity: 0.33;
        pointer-events: none;
    }
    > .Icon {
        display: block;
    }

    @media (hover: hover) {
        &:hover {
            color: var(--primary) !important;
        }
    }
}
