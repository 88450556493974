@import '../../../../../Common/src/scss/components/react/NotificationSubscription.scss';

.NotificationSubscription {
    &__notify-link {
        color: $light;

        &:active,
        &:focus {
            text-decoration-color: var(--primary);
        }

        &:hover {
            color: inherit;
        }
    }

    &__popup {
        color: $light;
        background-color: $dark;
        border: 2px solid color-mix(in srgb, var(--primary), transparent 50%);

        &__close {
            svg.Icon {
                color: $light;
            }
        }

        @keyframes fade-in {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        &__message {
            color: var(--primary);
        }

        &__recaptcha {
            color: $gray-secondary;

            a {
                font-size: 0.75rem;
                letter-spacing: 0.2em;
            }
        }
    }
}
