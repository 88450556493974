﻿@import '../../../../../Common/src/scss/components/react/ProductRating.scss';

.ProductRating {
    color: var(--primary);
    p span {
        margin-left: 0.5em;
        a {
            margin-left: 0.5em;
        }
    }

    p,
    a {
        font-family: var(--secondary-font);
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.07em;
        line-height: 1.43em;
    }

    &__BottomLine {
        &__Stars {
            .yotpo-bottomline {
                align-items: center;
                justify-content: center;
            }

            .yotpo-stars {
                padding-right: 5px !important;
                @include media-breakpoint-down(md) {
                    width: unset;
                }
            }
        }
    }

    .reviewSeparator {
        color: var(--primary);
        padding: 0 5px 0 5px;
    }

    .yotpo.bottomLine.yotpo-small,
    .yotpo-display-wrapper,
    .standalone-bottomline,
    .yotpo-bottomline.pull-left.star-clickable {
        width: 100%;
    }

    .yotpo-display-wrapper {
        .standalone-bottomline {
            .yotpo-bottomline {
                .rating-star {
                    color: var(--primary) !important;
                    font-family: var(--secondary-font);
                    font-size: 1.25rem;
                    font-weight: 300;
                    letter-spacing: 0.07em;
                    line-height: 1.43em;
                }
                a.text-m {
                    align-items: center;
                    color: var(--primary) !important;
                    font-family: var(--secondary-font);
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0.07em;
                    line-height: 1.43em;
                    margin-left: auto;
                }
            }
        }
    }
}
