﻿.navigation__brands {
    background: $dark;
    color: $gray-ghost;

    > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 3rem;

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
            padding: 0 0 0 9rem;
        }

        > li {
            display: block;
            padding: 0;
            margin: 0;

            > a {
                display: block;
                color: $gray-secondary !important;
                text-decoration: none !important;
                transition: color 350ms;
                font-size: 1.5rem;
                padding: 0 0 0.25em 0;
                margin: 0;
            }

            @media (hover: hover) {
                &:not(.active) > a:hover {
                    color: $light !important;
                }
            }

            &.active > a {
                color: var(--primary) !important;
                pointer-events: none;
            }
        }
    }
}
