﻿@import '../../../../../Common/src/scss/components/react/CatalogExplore.scss';

.CatalogExplore {
    &__Card__Details {
        border-color: rgba(205, 248, 76, 0.2);

        &__Content {
            &__Title {
                color: var(--primary);
            }
        }
    }
}
