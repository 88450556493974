@import '../../../../Common/src/scss/blocks/_formcontainerblock.scss';

.formcontainerblock {
    .FormSelection.FormSelection {
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        select {
            color: $gray-secondary;
            border-color: $primary-opaque;
            background-color: rgba(237, 237, 237, 0.1);
            width: 95%;
            height: $btn-height;
            padding-left: $padding-sm;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        select option:disabled {
            color: $gray-secondary;
            font-size: $font-size-sm;
            line-height: $line-height-base;
            letter-spacing: $letter-spacing;
            padding: 0.5rem 0.7rem;
        }
        select option {
            color: $gray-secondary;
            background: rgba(237, 237, 237, 0.1);
        }
        .Form__Element__Caption {
            display: none !important;
        }
    }

    .Form__Element.FormTextbox.FormTextbox--Textarea {
        clear: left;
        border-color: $primary-opaque;
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
        textarea {
            color: $light !important;
            border-color: $primary-opaque;
            background-color: rgba(237, 237, 237, 0.1);
            width: 100%;
            height: 9rem;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .Form__Element__Caption {
            height: 1.5rem;
            line-height: $line-height-base;
            letter-spacing: $letter-spacing;
            text-align: left;
            color: $light;
            display: block;
            padding-bottom: 2rem;
        }
        .Form__Element__Caption:after {
            content: none;
        }
        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $gray-secondary;
            opacity: 1; /* Firefox */
            font-size: $font-size-sm;
            line-height: $line-height-base;
            letter-spacing: $letter-spacing;
            padding: 0.5rem 0.7rem;
        }
    }

    .Form__Element.FormSubmitButton {
        text-transform: uppercase;
        font-family: var(--primary-font);
        font-weight: bold;
        font-size: $font-size-sm;
        line-height: $line-height-base;
        color: $dark;
        background-color: var(--primary);
        border: 0;
        box-shadow: none;
        border-radius: $btn-border-radius;
        display: block;
        width: 10rem;
        height: $btn-height;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
