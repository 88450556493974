﻿@import '../../../../../Common/src/scss/components/react/ProductPrice.scss';

.ProductPrice {
    color: var(--primary);
    margin-top: 0.5em;

    @include media-breakpoint-down(md) {
        margin-top: 0.25rem !important;
    }

    p {
        font-weight: bold;
    }
}
