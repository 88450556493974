﻿@use 'sass:math';
@import '../../../../../Common/src/scss/components/react/ColorSelector.scss';

$item-size: math.div($btn-height, 1px);

.ColorSelector {
    button.dropdown,
    .btn-group button > div {
        height: $item-size * 1px;
        width: $item-size * 1px;
    }
    .btn-group button.disabled::after {
        border-top: 2px solid var(--primary);
        width: math.sqrt(2 * $item-size * $item-size) * 1px;
    }
    button.dropdown {
        right: -40px;
    }
}
