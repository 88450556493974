﻿@import '../../../../../Common/src/scss/components/react/SearchBar.scss';

.SearchBar {
    top: 135%;
    border: 1px solid $primary-opaque;

    &.SearchBar__header {
        z-index: 200;
    }

    input.SearchBar__input {
        color: var(--primary);
    }

    &__bar {
        border-bottom: double var(--primary);
    }

    &__icon {
        background-color: $primary-input-background-color;
        color: $light;
    }

    &.theme-dark {
        border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);

        input.SearchBar__input,
        .SearchBar__icon {
            background-color: $gray-dark !important;
            color: $light;
        }
    }
}
