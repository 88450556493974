﻿.btn,
.btn-lg,
.btn-sm {
    text-transform: uppercase;
    font-family: var(--primary-font);
    font-weight: bold;
    letter-spacing: 1px;
}

.btn-primary {
    background-color: var(--primary);
    border: 1px solid transparent;
    color: $dark !important;
}

.btn-inverted {
    &:active,
    &:focus {
        color: $light;
    }
    @media (hover: hover) {
        &:hover {
            color: $light;
        }
    }
}

.btn-secondary,
.btn-primary {
    @media (hover: hover) {
        &:hover {
            background-color: $light;
            border: 1px solid $dark;
        }
    }

    &:active {
        opacity: 0.95;
    }

    &:focus {
        border-color: $dark;
    }
}

.btn-lg {
    @include font-size(1rem);
    line-height: 20px;
}

.btn {
    @include font-size(14px);
    line-height: 19px;
}

.btn-sm {
    @include font-size(12px);
    line-height: 16px;
}

.btn,
.btn-lg,
.btn-sm,
.btn:focus,
.btn-lg:focus,
.btn-sm:focus,
.btn:hover,
.btn-lg:hover,
.btn-sm:hover,
.btn:active,
.btn-lg:active,
.btn-sm:active {
    &.disabled {
        @extend .disabled;
    }
}

button:disabled {
    pointer-events: none;
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value, $value, rgba(0, 0, 0, 0.75), white);
        background-color: rgba(0, 0, 0, 0.75);
    }
}
