﻿@import '../../../../../Common/src/scss/components/react/ChildrenSelector.scss';

.ChildrenSelector {
    .child-list > button {
        background-color: initial;

        &.selected {
            background-color: initial;
        }
    }

    &__header {
        color: $light;

        &:hover {
            color: var(--primary);
        }
    }

    &__option {
        @include button-outline-variant($primary);

        transition: border 200ms, color 200ms;
        border: 2px solid rgba(205, 248, 76, 0.15) !important;
        color: $light;

        &:active {
            background-color: transparent !important;
            color: $light !important;
        }
        &:hover {
            color: $light;
            border-color: var(--primary) !important;
        }

        &--active {
            border: 2px solid var(--primary) !important;
            background-color: black !important;

            &:hover {
                color: $light;
            }
        }

        &--disabled {
            border: 2px solid rgba(205, 248, 76, 0.15) !important;
            color: rgba(205, 248, 76, 0.15) !important;

            &:hover {
                color: rgba(205, 248, 76, 0.15);
            }
        }
    }
}
