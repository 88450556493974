﻿.registration__block {
    p.form__field-required.paragraph-2 {
        display: none;
    }

    .registration__block__title__border {
        display: none;
    }

    button.btn-lg,
    .btn-group-lg > button.btn {
        line-height: 40px;
        font-size: 1.75rem;
    }
}
