.shopby_carousel {
    color: var(--primary);

    &__title {
        flex: 1;
    }

    &__subtitle {
        flex: 1;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 24px;
    }

    @include media-breakpoint-up(xl) {
        &__subtitle {
            flex: 1.3;
        }
    }

    &__tabs {
        padding-top: 30px;
        padding-bottom: 20px;
        &__tab {
            margin-right: 5%;
            a {
                opacity: 0.5;
                &.active {
                    text-decoration: underline;
                    text-decoration-color: var(--primary);
                    opacity: 1;
                }
            }
        }
    }

    &__panels {
        position: relative;
        padding-bottom: 1em;
        &__panel {
            margin-bottom: 50px !important; //overriding .slick-dotted.slick-slider margin
            &__slide {
                align-items: center;
                display: flex !important;
                flex-direction: column;
                img {
                    width: 90%;
                    padding: 20px;
                    border: 1px solid var(--primary);
                }

                &__title {
                    padding-top: 15px;

                    span.strike {
                        text-decoration: line-through;
                    }

                    span.sale {
                        color: $red;
                    }
                }

                &__shopbtn {
                    top: 20px;
                    letter-spacing: 1.5px;
                    padding-bottom: 5px;
                }
            }
        }

        .slick-slider {
            .slick-disabled {
                opacity: 0.5;
            }

            .slick-prev,
            .slick-next {
                &::before {
                    content: none;
                }
                height: 30px;
                width: 45px;
                bottom: -58px;
                left: unset;
                top: unset;

                svg {
                    color: var(--primary);
                    height: 100%;
                    width: 100%;
                }
            }

            .slick-prev {
                right: 50px;
                svg {
                    transform: rotate(180deg);
                }
            }

            .slick-next {
                right: 0px;
            }

            .slick-dots {
                display: flex;
                bottom: -30px;
                width: 90%;
                li {
                    &:only-child {
                        display: none;
                    }

                    opacity: 0.5;
                    width: 100%;
                    height: 1px;
                    margin: 0px;
                    button {
                        padding: 0px;
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary);
                        &::before {
                            content: none;
                        }
                    }

                    &.slick-active {
                        opacity: 1;
                    }
                }
                @include media-breakpoint-down(lg) {
                    width: 80%;
                }

                @include media-breakpoint-down(sm) {
                    width: 70%;
                }
            }
        }
    }
}

.width__full-vw .shopby_carousel {
    padding: 0 20px;
}
