﻿@import '../../../../../Common/src/scss/components/react/DetailsLinks.scss';

.DetailsLinks {
    margin-left: 1rem;

    .LinkItem,
    .ToolTip {
        @include media-breakpoint-down(sm) {
            margin-right: unset !important;
        }
    }
}
