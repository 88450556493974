@import '../../../../../Common/src/scss/components/react/PureTip.scss';

.PureTip {
    font-size: 1rem;

    &__content {
        background: $dark !important;
    }

    &__arrow {
        border-color: $dark !important;
    }

    &.danger {
        .PureTip__content {
            color: $danger;
        }
    }

    &.warning {
        .PureTip__content {
            color: $danger;
        }
    }

    &.success {
        .PureTip__content {
            color: $success;
        }
    }

    &.info {
        .PureTip__content {
            color: $light;
        }
    }
}
