﻿@import '../../../Common/src/scss/pages/_catalog_product.scss';

.catalog__product {
    &.product__filter {
        padding: 0 9rem;

        @include media-breakpoint-down(md) {
            padding: 0 1rem;
        }
    }

    .breadcrumb {
        background: none;

        @include media-breakpoint-down(xs) {
            margin-top: 2em;
        }

        li.breadcrumb-item,
        li.breadcrumb-item > a,
        li.breadcrumb-item::before {
            color: var(--primary) !important;
            font-family: var(--secondary-font);
            font-size: 12px;
            letter-spacing: 0.1425em;
        }
    }

    .ProductTitle {
        p,
        h3 {
            color: var(--primary);
        }
    }

    .ProductCode {
        color: var(--primary);
        text-transform: uppercase;
        margin-top: 0.5em;
    }

    .ProductOptions {
        color: $gray-secondary;

        .btn[data-toggle] {
            color: $gray-secondary;
        }
    }

    .ProductShortDescription {
        p {
            color: $light;
            margin-top: 1.5em;
            @include font-size(16px);
            font-weight: 300;
            letter-spacing: 1px;
            line-height: 1.5em;

            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 14px;
            }
        }
    }

    .ProductView {
        padding-right: 0 !important;

        @include media-breakpoint-down(sm) {
            padding-left: 0 !important;
        }

        input:not([type='checkbox']):not([type='radio']):not([type='submit']),
        select,
        button {
            background: $gray-dark;
            color: $light;
        }
    }

    .DetailsLinks {
        margin-top: 2em;
        width: 100%;
        margin-left: 0;

        a > strong {
            color: var(--primary);
            font-family: var(--secondary-font);
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.125em;
        }
    }

    .ProductDetails {
        @include media-breakpoint-down(sm) {
            padding: 0 !important;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem 1rem !important;

            [class*='col-'],
            .row {
                padding-right: 0 !important;
            }
        }
    }
}
