@import '../../../../../Common/src/scss/components/react/CustomerGiftCardsGrid.scss';

.CustomerGiftCardsGrid {
    @include dark-bg-default-styles;

    .Modal {
        .modal-header {
            i {
                color: $light !important;
            }
        }
    }

    @include media-breakpoint-down('md') {
        div.card {
            word-wrap: normal;
            font-size: 1.09rem;
        }
    }
}