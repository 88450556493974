﻿@import '../../../../../Common/src/scss/components/react/OrderHistory.scss';

.OrderHistory {
    @include dark-bg-default-styles;

    .Table {
        tr {
            color: $light;
        }
    }

    &__pagination {
        button {
            border: 2px solid $primary-opaque;
            color: $light;

            @include hover() {
                color: $light;
            }
        }

        button.btn-primary {
            color: $dark;
        }
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .AccordionTable {
        margin-left: 1rem;
        font-size: 1rem;
        tr,
        .btn {
            color: $light;
        }

        @include media-breakpoint-down(md) {
            tbody {
                td {
                    padding-right: 10px;
                    .btn-sm {
                        padding: 7px 20px;
                    }

                    a.tracking {
                        font-family: var(--tertiary-font);
                    }
                }
                td:last-child {
                    padding-right: 10px;
                    .btn-sm {
                        padding: 7px 5px 20px 5px !important;
                    }

                    a.tracking {
                        font-family: var(--tertiary-font);
                    }
                }
            }
        }
    }
}
