﻿$filter-font-family: var(--tertiary-font);

@import '../../../../../Common/src/scss/components/react/FilterDropdown.scss';

.FilterDropdown {
    button,
    button:hover {
        color: var(--light);
    }
}
