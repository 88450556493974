@import '../../../../../Common/src/scss/components/react/HeaderSearchResult.scss';

.HeaderSearchResult {
    &__title > a {
        color: var(--primary);
    }

    &__price.ProductPrice {
        margin-top: 0px;
    }

    &.theme-dark {
        .HeaderSearchResult {
            &__title > a {
                color: var(--primary);
            }

            &__price p {
                color: var(--primary);
            }
        }
    }
}
