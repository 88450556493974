.d-list-item {
    display: list-item !important;
}

.d-sm-list-item {
    @include media-breakpoint-up(sm) {
        display: list-item !important;
    }
}

.d-md-list-item {
    @include media-breakpoint-up(md) {
        display: list-item !important;
    }
}

.d-lg-list-item {
    @include media-breakpoint-up(lg) {
        display: list-item !important;
    }
}

.d-xl-list-item {
    @include media-breakpoint-up(xl) {
        display: list-item !important;
    }
}

@mixin dark-bg-default-styles {
    input:not([type='submit']),
    select {
        background: $primary-input-background-color !important;
    }
    .card {
        background: none;
        padding: 1em !important;
        button {
            color: $light;
        }
    }
    .modal-footer {
        background: $gray-dark;
        border-radius: 0;
    }
    .modal-content {
        background: var(--primary);
        color: $dark;
    }
}
