﻿@import '../../../../../Common/src/scss/components/react/CartTable.scss';

.CartTable {
    .table {
        color: var(--primary);
        font-family: var(--secondary-font);

        .tablerow,
        .tablehead {
            border-bottom-color: color-mix(in srgb, var(--primary), transparent 80%);
        }

        .tablerow {
            &.dirty .tablecell {
                background-color: initial;
                &.subtotal > p {
                    text-decoration: line-through;
                    font-weight: normal;
                }
            }

            .tablecell.price {
                font-weight: bold;
            }
        }

        button i,
        button .Icon {
            color: $light;
            @media (hover: hover) {
                transition: color 250ms;
                &:hover {
                    color: var(--primary);
                }
            }
        }
    }
}
