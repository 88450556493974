﻿@import '../../../../../Common/src/scss/components/react/ProductBadge.scss';

.ProductBadge {
    font-size: 14px;
    margin-bottom: 0.5em;
    .badge {
        color: var(--primary);
        background: none;
        border: 1px solid var(--primary);
    }
}
