@import '../../../../../Common/src/scss/components/react/ResultsGrid.scss';

.ResultsGrid {
    &__expansion-close {
        .CloseExpansion_Hover {
            color: var(--primary);
        }
    }

    &__expansion {
        width: 100%;
    }
}
