﻿@import '../../../../../Common/src/scss/components/react/ProductGallery.scss';

.ProductGallery {
    .MediaConverter + .Carousel {
        margin-top: 4rem;
        @include media-breakpoint-down(md) {
            margin-top: 2rem;
        }

        .CarouselButton {
            margin: 1em;
            @include media-breakpoint-down(sm) {
                margin: 0.67em;
            }
        }
    }

    .Carousel .CarouselItem {
        font-size: 1rem;
        width: 5em;
        height: 5em;
        border-radius: 100%;
        border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        &.selected {
            border: 0.2em solid var(--primary);
        }
        .MediaConverter,
        img {
            height: 100% !important;
            width: 100% !important;
            object-fit: cover !important;
        }
        @include media-breakpoint-down(sm) {
            font-size: 0.67rem;
        }
    }

    .slick-track {
        .slick-slide {
            > div > div {
                display: flex !important;
                justify-content: center;
                align-items: center;
                .btn {
                    @include media-breakpoint-down(xs) {
                        font-size: 0.67rem;
                    }
                    margin: 0 auto;
                    border-radius: 100%;
                    width: 5em !important;
                    height: 5em;
                    background: white;
                    overflow: hidden;
                    &.selected {
                        border: 0.25em solid var(--primary);
                    }
                    > img {
                        height: auto !important;
                        width: auto !important;
                        object-fit: cover !important;
                    }
                }
            }
        }
    }
    .ArrowButton > i {
        color: $light !important;
    }
}
