﻿.CatalogCard {
    padding: 20px;
    &__Container {
        border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
        height: 100%;
        padding: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__Top {
            justify-content: flex-end;
            display: flex;

            .ProductBadge {
                justify-content: flex-start !important;
                display: flex;
                flex-direction: row;
                flex: 1 1 0;
                .badge {
                    font-size: 0.8rem;
                }
            }
        }

        img {
            width: 100%;
        }

        &__Title {
            text-transform: uppercase;
            font-size: 1.1875rem;
            color: var(--primary);
            letter-spacing: 0.13157em;
            line-height: 1em;
        }

        table {
            font-size: 0.75rem;
            table-layout: fixed;
            max-width: 100%;

            td {
                line-height: 1em;
                vertical-align: bottom;
                span {
                    padding: 0.25em 0;
                }
            }
            th {
                font-weight: bold;
                padding-right: 2em;
                padding-top: 1em;
                text-transform: uppercase;
            }
            td {
                font-weight: 200;
            }
        }

        &__FacetTable {
            display: flex;
            flex-direction: row;
            .ProductPrice {
                margin-top: 0px;
                p {
                    font-size: inherit;
                }
            }

            .notify-link {
                margin-bottom: 0.5em;
                font-size: 0.75rem;
                color: $light;
                text-decoration: underline;
                display: block;

                &:active,
                &:focus {
                    text-decoration-color: var(--primary);
                }
            }

            .product-badge {
                display: block;
                text-transform: uppercase;
                font-weight: 600;
                color: $orange-crush;
                margin-bottom: 0.5em;
                font-size: 0.833em;
            }

            table {
                table-layout: fixed;
                min-width: 100%;
                tbody {
                    tr {
                        height: 4em;
                        &:nth-child(odd) {
                            background: $dark;
                        }

                        &.clickable {
                            cursor: pointer;
                        }
                    }
                }

                td {
                    vertical-align: bottom;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    padding: 0.75em 1em 0.75em 0.75em;
                }
            }

            &__Body {
                flex: 1 1 0%;
                @include branded-scroll-x($dark, var(--primary), 0.5rem);
            }
            &__Tail {
                table tr {
                    padding-left: 1rem;
                }

                &__Input {
                    height: 2.5em;
                    width: 6rem;
                    position: relative;
                    font-size: 0.875rem !important;
                    margin-right: 10px;

                    .NumberInput {
                        box-sizing: border-box;
                        background: #ffffff20 !important;
                        margin: 0;
                        width: 100%;
                        font-size: inherit !important;
                        font-weight: 200;
                        text-align: center !important;
                        padding: 0.3rem 0.5rem !important;
                        &.disabled,
                        &:disabled,
                        &[disabled] {
                            background: none;
                            color: $gray-medium !important;
                            pointer-events: none;
                            + .btn {
                                opacity: 0.33;
                                pointer-events: none;
                            }
                        }
                    }

                    input + .btn {
                        right: 6px;
                        left: auto;
                    }
                    .btn {
                        position: absolute;
                        left: 6px;
                        padding: 0 0.5em;
                        bottom: 2px;
                        top: 2px;
                        background: none;
                        height: auto;
                        font-size: inherit !important;
                        color: $light;
                        &.disabled,
                        &:disabled,
                        &[disabled] {
                            opacity: 0.33;
                        }
                    }
                }
            }
        }

        .ProductInput {
            height: 2em;

            button.btn.btn-primary:disabled {
                color: $dark !important;
            }
        }
    }
}
