﻿@import '../../../../Common/src/scss/blocks/_tabbed.scss';

.tabbed__content {

    & .nav-tabs {

        & .nav-link {
            @extend .subheader;
            @include font-size(21);
            color: $light;
        }

        & .nav-link.active {
            background-color: $gray-dark;
        }
    }
}
