@import '../../../../../Common/src/scss/components/react/CustomerAddressesGrid.scss';

.CustomerAddressesGrid {
    @include dark-bg-default-styles;

    input[type='checkbox']:checked {
        background-color: var(--primary) !important;
    }

    select {
        border: 2px solid $primary-opaque;
        outline: unset;
    }

    select:focus {
        border-color: var(--primary) !important;
    }

    select > option {
        background-color: $dark !important;
        color: var(--primary) !important;
    }

    .Modal {
        .modal-header {
            i {
                color: $light !important;
            }
        }
    }
}
