﻿@import '../../../../Common/src/scss/pages/_commonnode.scss';

.catalog-node {
    ul.nav-tabs {
        > li {
            a.nav-link {
                color: var(--primary);
            }
        }
    }
    @include media-breakpoint-down(md) {
        ul.nav-tabs {
            > li {
                padding: 0px 25px;
            }
        }
    }
}
