﻿@import '../../../../../Common/src/scss/components/react/ProductReviews.scss';

.yotpo.yotpo-main-widget {
    .main-widget.yotpo-display-wrapper {
        background: $gray-dark !important;
    }

    .write-question-review-button-icon,
    .yotpo-label-container,
    .yotpo-logo-title,
    .yotpo-icon-yotpo-logo,
    .yotpo-icon.yotpo-icon-circle-checkmark,
    .yotpo-icon-seperator,
    .yotpo-reviews-filters {
        display: none !important;
    }

    .yotpo-main,
    .yotpo-nav-wrapper > span,
    .reviews-qa-label,
    .reviews-amount,
    .yotpo-icon.yotpo-icon-heart,
    .y-label.yotpo-user-title,
    .yotpo-action > span,
    .yotpo-helpful > span,
    .yotpo-user-letter,
    div.write-review,
    div.yotpo-mandatory-explain,
    .y-label,
    .yotpo-thankyou-content > span,
    .yotpo-icon-button-text span.yotpo-thankyou-confirmation,
    span.yotpo-thankyou-publish,
    span.yotpo-icon-button-text,
    .yotpo-pager > a,
    .yotpo-read-more,
    .yotpo-syndication-reference > span {
        color: var(--primary) !important;
    }

    .yotpo-icon.yotpo-icon-star,
    .yotpo-icon.yotpo-icon-empty-star,
    .yotpo-thankyou-header > span:not(.yotpo-icon-heart),
    .content-question {
        color: $light !important;
    }

    #yotpo_input_question_content {
        background-color: rgba(216, 216, 216, 0.1);
        border: 2px solid rgba(205, 248, 76, 0.2);
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 20px;
        color: #fff;
    }

    #yotpo_input_question_content:focus {
        border-color: var(--primary) !important;
    }

    div.yotpo-thank-you {
        background-color: $gray-dark !important;
    }

    div.bottom-line-items {
        text-align: unset !important;

        @include media-breakpoint-down(sm) {
            display: table;
            margin: 0 auto;
            text-align: center !important;
        }
    }

    div.bottom-line-items > span.avg-score {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-base;
        line-height: 1.625rem !important;
        padding-right: 5px !important;

        @include media-breakpoint-down(sm) {
            width: unset !important;
            padding-right: 10px !important;
            padding-top: unset;
            height: 1.75rem;
            text-align: right;
            line-height: unset;
        }
    }

    div.bottom-line-items > span.yotpo-filter-stars.rating-stars-container {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-base !important;
        height: 1.75rem !important;
        line-height: 1.625rem !important;
        padding-right: 5px !important;

        @include media-breakpoint-down(sm) {
            display: inline-flex;
            float: unset;
            padding-right: 10px !important;
            padding-top: unset;
        }
    }

    .reviews-qa-labels-container {
        margin-left: unset;
        position: unset;
        display: unset;
        align-items: center;

        @include media-breakpoint-down(sm) {
            display: inline-flex;
            float: unset;
            height: 1.75rem !important;
            padding-top: unset;
        }
    }

    .reviews-qa-label {
        color: var(--primary) !important;

        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: center;
            width: unset !important;
        }
    }

    div.bottom-line-items > span.yotpo-filter-stars.rating-stars-container > span.yotpo-icon {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-base !important;
        height: 1.625rem !important;
        line-height: 1.625rem !important;
    }

    .review-question-header {
        color: var(--primary) !important;
        font-family: var(--primary-font) !important;
        font-size: $h4-font-size !important;
        font-weight: bold !important;
        padding-right: 0.625rem !important;
        text-transform: uppercase !important;

        @include media-breakpoint-down(sm) {
            display: block;
            justify-content: center;
            padding-bottom: 1.25rem;
        }
    }

    .write-first-review-button,
    .write-first-question-button {
        background: #000 !important;
        border: 1px solid var(--primary) !important;
        color: var(--primary) !important;
        height: 2.25rem !important;
        width: 17.1875rem !important;
    }

    button.write-question-review-button {
        background: #000 !important;
        border-color: var(--primary) !important;
        height: 2.25rem !important;
        text-transform: uppercase !important;
        width: 10.625rem !important;
    }

    button.write-question-button {
        margin: unset !important;
    }

    .yotpo-submit {
        background: #000 !important;
        border: 1px solid var(--primary) !important;
        color: var(--primary) !important;
        font-family: var(--primary-font) !important;
        font-size: 0.75rem !important;
        font-weight: bold !important;
        height: 2.25rem !important;
        padding: unset !important;
        width: 10.625rem !important;
    }

    .write-question-review-button-text {
        background: #000 !important;
        color: var(--primary) !important;
        font-family: var(--primary-font) !important;
        font-size: 0.75rem !important;
        font-weight: bold !important;
    }

    span.yotpo-icon {
        background: transparent !important;
        color: var(--primary) !important;
    }

    span.yotpo-icon-profile {
        background: #000 !important;
        color: var(--primary) !important;
        border: 1px solid var(--primary) !important;
    }

    .yotpo-nav-wrapper {
        border-color: var(--primary) !important;
    }

    span.yotpo-user-name {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }

    span.yotpo-user-title {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-sm !important;
        line-height: 1.5rem !important;
    }

    span.yotpo-review-date,
    .yotpo-review-wrapper > .content-review,
    .yotpo-review-wrapper > .content-review > .rest-content-collapsed {
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-sm !important;
        letter-spacing: $letter-spacing-1 !important;
        line-height: 1.25rem !important;
    }

    .footer-additional-actions {
        @include media-breakpoint-down(md) {
            height: 1.625rem !important;
        }
    }

    div.yotpo-grouping-reference {
        margin-top: 5px !important;
        width: 26rem;


        @include media-breakpoint-down(md) {
            margin-top: 10px !important;
            width: 21rem;
        }
    }

    span.yotpo-review-date {
        color: var(--primary) !important;

        @include media-breakpoint-down(md) {
            line-height: 1.625rem !important;
            vertical-align: middle;
        }
    }

    .yotpo-review-wrapper > .content-review,
    .yotpo-review-wrapper > .content-review > p.rest-content-collapsed {
        color: $light !important;
    }

    .yotpo-main > .content-title {
        color: var(--primary) !important;
        font-family: var(--secondary-font) !important;
        font-size: $font-size-base !important;
        letter-spacing: 2.5px !important;
        line-height: 1.2rem !important;
    }

    span.yotpo-icon-share {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-sm !important;
        letter-spacing: $letter-spacing-2 !important;
        line-height: 1.5rem !important;
    }

    div.yotpo-helpful > span.label-helpful {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-sm !important;
        letter-spacing: $letter-spacing-1 !important;
        line-height: 1.25rem !important;
    }

    div.yotpo-helpful > span.vote-sum {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: 1rem !important;
        letter-spacing: $letter-spacing-1 !important;
        line-height: 1.5rem !important;
    }

    div.yotpo-icon-btn {
        background: #000 !important;
        border: 1px solid var(--primary) !important;
        color: var(--primary) !important;
        font-family: var(--primary-font) !important;
        font-size: 0.75rem !important;
        font-weight: bold !important;
        height: 2.25rem !important;
        width: 10.625rem !important;
    }

    div.socialize > div > div.yotpo-icon-btn {
        background: #000 !important;
        border: 1px solid var(--primary) !important;
        color: var(--primary) !important;
        font-family: var(--primary-font) !important;
        font-size: 0.75rem !important;
        font-weight: bold !important;
        height: unset !important;
        width: unset !important;
    }

    div.transparent-color-btn {
        border: unset !important;
        background: transparent !important;
    }

    span.yotpo-action > span.y-label {
        font-family: var(--tertiary-font) !important;
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
    }

    div.yotpo-syndication-reference {
        float: left;
        margin-top: 0 !important;
        padding-top: 0 !important;
        width: 10rem;

        @include media-breakpoint-down(md) {
            height: 1.625rem !important;
        }

        span::before {
            color: var(--primary) !important;
            content: '|' !important;
            font-family: var(--tertiary-font) !important;
            font-size: 0.875rem !important;
            letter-spacing: 1px !important;
            line-height: 1.25rem !important;
            padding: 0 0.33em;
        }

        span {
            color: var(--primary) !important;
            font-family: var(--tertiary-font) !important;
            font-size: 0.875rem !important;
            line-height: 1.5rem !important;

            @include media-breakpoint-down(md) {
                line-height: 1.625rem !important;
                vertical-align: middle;
            }
        }

        .syndication-reference-link > img {
            height: 22px !important;
            width: 38px !important;
        }
    }
}
