/*
 ==== Color Palette ==========================
*/
// Override Bootstrap defaults
$green: #cdf84c;
$orange-crush: #fd9e3b;
$orange: $orange-crush;
$red-smash: #f34336;
$red: red;
$white: #fff;
$black: #000000;
$red: $red-smash;

$primary: $green;
$primary-opaque: rgba(205, 248, 76, 0.15);
$primary-alpha: var(--primary);
$secondary: $orange-crush;
$tertiary: $red-smash;
$light: $white;
$dark: $black;

$info: $primary;
$success: $primary;
$warning: $orange-crush;
$danger: #ff3352;

$primary-input-background-color: rgba(216, 216, 216, 0.1);

$gray-dark: #1d1d1b;
$gray-primary: #212221;
$gray-secondary: #78777a;
$gray-tertiary: #54575a;
$gray-ghost: #f3f6f6;
$semi-dark: #232a2d;
$gray-light: #f2f1f1;
$gray-medium: #d4d4d4;
$checkbox-fill-color: $primary;

$text-highlight: rgba(215, 239, 255, 0.75);
/*
 ==== Fonts ==============================
*/
$primary-font: 'Quantico', sans-serif;
$secondary-font: 'Titillium-Web', sans-serif;
$tertiary-font: 'Titillium-Web', sans-serif;
$tertiary-font-light: 'Titillium-Web', sans-serif;
$font-family-base: $primary-font !default;
$font-weight-bolder: 900 !default;
$font-size: 1rem;
$font-size-base: 1.2rem; // Assumes 16px base * 1.2 = 19ish
$font-size-sm: 0.875rem;
$line-height-base: 1.4;
$letter-spacing: 1.5px;
$letter-spacing-1: 1px;
$letter-spacing-2: 2px;
$enable-responsive-font-sizes: true !default;

// Default css variable fonts
:root {
    --primary-font: #{$primary-font};
    --secondary-font: #{$secondary-font};
    --tertiary-font: #{$tertiary-font};
    --font-family-base: #{$primary-font};
    --primary-contrast: #{$dark};
    --secondary-contrast: #{$dark};
    --checkbox-fill-color: var(--primary);
    --link-color: #{$white};
}

$h1-font-size: 5.18rem;
$h2-font-size: 4.31rem;
$h3-font-size: 3.56rem;
$h4-font-size: 48px;
$h5-font-size: 2.5rem;
$h6-font-size: 2rem;

$h1-mobile-font-size: 3.56rem;
$h2-mobile-font-size: 3rem;
$h3-mobile-font-size: 2.5rem;
$h4-mobile-font-size: 2rem;
$h5-mobile-font-size: 1.6rem;
$h6-mobile-font-size: 1.4rem;

$headings-font-weight: 900 !default;

$display1-size: 120px;
$display2-size: 90px;
$display3-size: 38px;
$display4-size: 19px;

$display1-weight: 900;
$display2-weight: 900;
$display3-weight: 900;
$display4-weight: 900;

/*
 ==== Links  ==============================
*/
$link-color: $black;
//$link-decoration: underline !default;
/*
 ==== Navigation  ==============================
*/
$navbar-dark-active-color: $white;
$navbar-dark-hover-color: $primary;
$navbar-dark-color: $primary;
/*
 ==== Buttons  ==============================
*/
$input-btn-font-family: 'Roboto', sans-serif !default;
$btn-font-weight: 900;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
$btn-height: 46px;
/*
 ==== Input  ==============================
*/
$input-font-size: 24px !default;
$input-border-color: $black;
$input-border-width: 2px;
$input-border-radius: 0px;
$input-border-radius-lg: 0px;
$input-border-radius-sm: 0px;
$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-padding-y-lg: 1rem !default;
$input-btn-padding-x-lg: 1rem !default;
/*
 ==== Alerts  ==============================
*/
$alert-border-radius: 0px;
$alert-border-width: 2px !default;
$alert-border-level: 0 !default;
/*
 ==== Forms  ==============================
*/
$enable-validation-icons: false;
$form-feedback-valid-color: $black;

/*
==== Padding  ==============================
*/
$padding-sm: 0.625rem; // Assumes 16px base * .625 = 10px
