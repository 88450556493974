﻿.ProductRecommendations {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    box-sizing: content-box;

    button.ArrowButton {
        display: flex !important;
        height: auto;
        width: auto;
        top: 50%;

        &:before {
            content: none;
        }

        i {
            font-size: 36px;
            color: var(--primary);
        }

        &.slick-prev {
            left: -40px !important;
        }

        &.slick-next {
            right: -40px !important;
        }
    }

    .slick-slider {
        height: 100%;
        display: flex;
        width: 100%;

        .slick-list {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: center;
        }

        .slick-track {
            display: flex;

            .slick-slide {
                display: flex;
                align-items: stretch; 
                justify-content: center; 
                flex: 1;
                height: inherit; 
                min-height: 200px; 
                box-sizing: border-box; 
                opacity: 0.2;
                transition: opacity 0.3s;
    
                &.slick-active {
                    opacity: 1;
                }

                img {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }
            }
    
            .slick-slide > div {
                width: 350px;
                display: flex;
                height: 100%
            }
        }
    }

    .CatalogQuickView {
        @keyframes openQuick {
            from {
                height: 0;
            }

            to {
                height: 100%;
            }
        }
        transform: translateY(0);
        animation-name: openQuick;
        animation-duration: 2s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
    }

    &__header {
        text-decoration: underline;
        text-decoration-color: $primary;
        text-decoration-thickness: 3px;
        font-weight: bolder;
        text-underline-offset: 3px;
        text-underline-position: under;
        padding: 15px 0px;
        letter-spacing: 1px;
        margin-top: 2em;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    &__Product {
        &__image {
            height: 250px;
            width: 250px;
            object-fit: contain;
            justify-content: center;

            @include media-breakpoint-down(sm) {
                height: 175px;
                width: 175px;
            }
        }

        &__quickview {
            display: flex;
            p {
                font-weight: bold;
                font-size: .875rem;

                @media (hover: hover) {
                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }

        &__related {
            display: flex;
            width: 100%;
            box-shadow: 5px 10px 20px 0px rgba(0,0,0,.3);
            border-radius: 10px;
            margin: 1rem;
            align-items: stretch;

            p {
                margin-bottom: 8px;
                justify-content: center;
                text-align: center;

                @media (hover: hover) {
                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }

        &__rating {
            display: flex;
            width: 100%;
            justify-content: center;
        }

        &__content {
            display: flex;
            flex-direction: column;
            flex-flow: wrap;
            width: 100%;
            height: 100%;
            align-content: space-between;
            gap: 10px;
            padding: 0px 1rem 0px 1rem;
            height: 100%;
            justify-content: center;
            text-align: center;
            margin-bottom: 1rem;

            .item-name {
                font-size: 16px;
                text-align: center;
                align-items: stretch;
                justify-content: center;

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }
        }

        &__content > div {
            width: 100%;
            justify-content: center;
        }

        .subheader {
            line-height: 1em;
            @include font-size(19);

            @media (hover: hover) {
                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                justify-content: center;
            }
        }

        .ProductRating {
            .yotpo.bottomLine {
                .yotpo-display-wrapper {
                    .yotpo-clr {
                        content: "<span class='yotpo-stars'><span class='yotpo-icon yotpo-icon-empty-star pull-left'></span><span class='yotpo-icon yotpo-icon-empty-star pull-left'></span><span class='yotpo-icon yotpo-icon-empty-star pull-left'></span><span class='yotpo-icon yotpo-icon-empty-star pull-left'></span><span class='yotpo-icon yotpo-icon-empty-star pull-left'></span> </span>" !important;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down('md') {
        .slick-active.slick-center + div.slick-active {
            opacity: 0.2;
            transition: opacity 0.3s;
        }
    }
}
