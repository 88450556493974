﻿@import '../../../../../Common/src/scss/components/react/ProductView.scss';

.ProductView {
    margin-bottom: 0;
    
    .ProductDetails {
        .ProductTitle {
            p {
                @include font-size(3.56rem);
                letter-spacing: -0.5px;
                line-height: 0.97em;
                text-transform: uppercase;
                font-weight: $font-weight-bolder;
                font-family: var(--primary-font);
                margin-bottom: 0.5rem;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .ProductDetails {
            .ProductTitle {
                p {
                    @include font-size(2.5rem);
                }
            }
        }
    }
}
