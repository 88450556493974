@import '../../../../../Common/src/scss/components/react/SearchFacet.scss';

.SearchFacet {
    &.Dropdown {
        .Dropdown__button {
            color: var(--primary);
            font-weight: bold;
        }

        .Dropdown__drawer {
            .SearchDropdownFacet__option {
                color: var(--primary);
            }
        }
    }

    &.SearchCheckboxFacet,
    &.SearchRadioFacet {
        .form-check {
            &-input {
                background: transparent;
            }

            &-label {
                font-family: var(--secondary-font) !important;
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
}
