﻿body {
    color: $white;
}

strong {
    font-weight: bold;
}

.subheader {
    @include font-size(23px);
    letter-spacing: 0.1em;
    line-height: 0.9em;
    font-weight: 400;
}

.display {
    letter-spacing: -0.025em;
}

.display-1,
.display-2,
.display-3 {
    letter-spacing: 2.5px;
}

.display-1 {
    line-height: 0.85em;
    font-size: 8.93rem;
}

.display-2 {
    line-height: 1em;
    font-size: 7.43rem;
}

.display-3 {
    line-height: 1.05em;
    font-size: 6.18rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 1.5px;
}

p {
    @include font-size(19px);
}

.paragraph-2 {
    @include font-size(16px);
}

.paragraph-3 {
    @include font-size(14px);
}

blockquote {
    @include font-size(19px);
    font-family: var(--tertiary-font);
    letter-spacing: 0.25px;
    line-height: 1.68em;
    text-align: center;
}

.text-outlined-dark {
    color: $gray-dark;
    text-shadow: -1px -1px 0 var(--primary), 1px -1px 0 var(--primary), -1px 1px 0 var(--primary),
        1px 1px 0 var(--primary);
}

.text-outlined-light {
    color: var(--primary);
    text-shadow: -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black;
}

.list-1 {
    @include font-size(16px);
    letter-spacing: 1px;
    line-height: 24px;
}

.list-2 {
    @include font-size(14px);
    letter-spacing: 1px;
    line-height: 20px;
}

.quote-text {
    &::before {
        content: open-quote;
        position: absolute;
        font-size: 137px;
        z-index: 2;
        margin-left: 45%;
        margin-top: -20px;
    }

    @include font-size(33px);
    font-style: italic;
    letter-spacing: 1px;
    line-height: 50px;
    text-align: center;
    color: var(--primary);

    &::after {
        content: close-quote;
    }
}

// MOBILE
@include media-breakpoint-down('md') {
    .subheader {
        @include font-size(19px);
    }
}
