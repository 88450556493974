﻿@import '../../../../../Common/src/scss/components/react/CatalogList.scss';

.CatalogList {
    .CatalogListCard {
        border-color: color-mix(in srgb, var(--primary), transparent 80%);
        .ProductBadge {
            margin: none;

            .badge {
                border: none;
                font-size: 0.8333em;
                color: $orange-crush;
                padding: 0px;
            }
        }

        .ProductPrice p {
            font-size: inherit;
            font-weight: normal;
        }

        &__Details {
            &__Title {
                font-size: 1.1875rem;
                letter-spacing: 0.13157em;
                color: var(--primary);
            }

            border-color: rgba(205, 248, 76, 0.2);

            table {
                th,
                td {
                    font-size: 0.725rem;
                    letter-spacing: 0.1em;
                }
                th {
                    font-family: var(--secondary-font);
                }
                td {
                    font-family: var(--tertiary-font);
                    font-weight: 200;
                }
            }

            .ProductListTable {
                &.Grid {
                    @include branded-scroll-x($dark, var(--primary), 0.75rem);
                    border: unset;

                    .Grid {
                        &__cell {
                            font-family: var(--tertiary-font);
                            font-size: 0.85rem;

                            &--header {
                                font-family: var(--secondary-font);
                                font-size: 0.725rem;
                                letter-spacing: 0.1em;
                                background-color: $gray-dark !important;
                            }

                            &--even {
                                &:not(.Grid__cell--header) {
                                    background-color: $gray-dark;
                                }
                            }

                            &--odd {
                                &:not(.Grid__cell--header) {
                                    background-color: $dark;
                                }
                            }

                            &:not(.Grid__cell--header) {
                                font-weight: 200;
                            }

                            .gallery-btn {
                                font-size: 1.25rem;
                            }

                            .NumberInput {
                                padding: 0.5em 3em !important;
                            }
                        }
                    }
                }
            }

            &__Input.ProductInput {
                font-size: 0.75rem;
                button {
                    font-size: 0.75rem;
                    &.btn:disabled {
                        color: $dark !important;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .product-link {
                color: $light;
            }

            &__Details {
                &__CatalogListDetailsCards {
                    @include branded-scroll-y($dark, var(--primary), 0.3rem);

                    &__Container {
                        background-color: $dark;
                    }

                    .ProductInput {
                        margin-top: 0px;
                    }

                    table {
                        margin-bottom: 1rem;

                        td {
                            text-align: left !important;
                        }

                        th {
                            font-weight: bold;
                            padding-right: 2em;
                            padding-top: 0.25em;
                            text-transform: uppercase;
                            vertical-align: top;
                        }
                        td {
                            font-weight: 200;
                            padding-top: 0.25em;
                        }
                    }

                    .notify-link {
                        margin-bottom: 0.5rem;
                    }

                    .ProductDetailCard {
                        .ProductInput {
                            font-size: 0.75rem;
                            button.btn {
                                font-size: 0.75rem;
                            }
                        }
                    }

                    @include branded-scroll-y(rgba(205, 248, 76, 0.2), var(--primary), 0.3rem);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            &__Details {
                &__CatalogListDetailsCards {
                    .ProductDetailCard {
                        .ProductInput {
                            input {
                                padding: 0.5rem 0.7rem !important;
                            }

                            button {
                                &.btn-add {
                                    right: 0;
                                }
                                &.btn-sub {
                                    left: 0;
                                }

                                &:hover {
                                    color: inherit;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
