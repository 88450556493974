@import '../../../../Common/src/scss/blocks/_yotpo.scss';

.YotpoCustomGalleryHeader {
    align-items: center !important;
    justify-content: center !important;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-end !important;
        justify-content: flex-start !important;
    }

    &-title {
        @include media-breakpoint-up(lg) {
            margin-left: 2rem;
        }

        font-family: var(--primary-font);
        font-weight: $font-weight-bold;
        font-size: 2rem;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        text-align: center;
    }

    &-subtitle {
        @include media-breakpoint-up(lg) {
            margin-left: 1rem;
        }

        margin-bottom: 5px;
        font-family: var(--primary-font);
        font-size: 1rem;
        text-align: center;
    }
}
