﻿@import '../../../../../Common/src/scss/components/react/AccordionDropdown.scss';

.AccordionDropdown {
    .btn {
        font-weight: 300;
        transition: color 250ms ease-in-out;
        font-family: var(--tertiary-font);
        @media (hover: hover) {
            &:not(.disabled):not(:disabled):not([disabled]):hover {
                color: var(--primary);
            }
        }
    }

    .card {
        transition: border-color 250ms ease-in-out;
        border: 2px solid color-mix(in srgb, var(--primary), transparent 80%);
        @media (hover: hover) {
            &:not(.disabled):not(:disabled):not([disabled]):hover {
                border-color: var(--primary);
            }
        }
    }
}
