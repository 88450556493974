﻿@import '../../../../Common/src/scss/blocks/_accountpasswordresetblock.scss';

.accountpasswordresetblock {
    h2 {
        font-weight: bold;
        font-family: var(--primary-font);
        font-size: 2.5rem;
        line-height: 1.875rem;
        letter-spacing: $letter-spacing;
    }

    .invalid-feedback {
    }
}
