﻿@import '../../../../../Common/src/scss/components/react/CatalogFilters.scss';

.CatalogFilters {
    > section {
        > div:not(:last-of-type) {
            border-right: 2px solid var(--primary);
        }
    }

    &__menuBtn {
        button {
            color: $white;
        }
    }
}
