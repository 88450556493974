@import '../../../../../Common/src/scss/components/react/AddressVerificationDrawer.scss';

.AddressVerificationDrawer {
    .Drawer__slider {
        color: $gray-medium;
        padding: 2rem;
        background: $gray-dark;

        &.anchor-right,
        &.anchor-left {
            max-width: 100vw !important;
            min-width: auto !important;
            width: 35rem !important;
        }
    }

    &__ListItem {
        margin: 1rem 0 0 0;
        border-color: color-mix(in srgb, var(--primary), transparent 80%);
        transition: border-color 250ms ease-in-out;

        @media (hover: hover) {
            &:hover {
                border-color: var(--primary);
            }
            &.dotted:hover > .address,
            &.dotted:hover > .icon {
                color: $light;
            }
        }

        > .icon {
            transition: color 250ms ease-in-out;
            color: $gray-medium;

            &.green {
                color: var(--primary);
            }
            &.red {
                color: $warning;
            }
        }

        > .address {
            color: $gray-medium;
            transition: color 250ms ease-in-out;
        }

        &:not(.dotted) > .address {
            color: var(--primary);
        }
    }

    &__ListItem + .btn {
        color: $gray-medium;
        @media (hover: hover) {
            &:hover {
                color: var(--primary);
            }
        }
    }
}
