@import '../../../../../Common/src/scss/components/react/MultiPageCheckout.scss';

.MultiPageCheckout {
    background: $dark;

    &__mobile-header {
        background: $dark;
    }

    &__col.col-right {
        background: $gray-dark;
    }

    &__nav {
        &__link {
            opacity: 1 !important;
            color: color-mix(in srgb, var(--primary), transparent 50%);
            font-family: var(--tertiary-font);
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 1px;
            transition: color 250ms;

            &.active {
                text-decoration: underline;
                text-decoration-color: currentColor;
                text-decoration-thickness: 1px;
            }
            &.active,
            &:active,
            &:focus,
            &:focus-visible {
                color: var(--primary);
            }

            &.disabled,
            &[disabled],
            &:disabled {
                opacity: 1 !important;
            }

            &:not(.disabled):not([disabled]):not(:disabled) {
                &.active,
                &:active,
                &:focus,
                &:focus-visible {
                    color: var(--primary);
                }
                @media (hover: hover) {
                    &:hover {
                        color: var(--primary);
                        &:not(.active) {
                            text-decoration: none;
                        }
                    }
                }
            }

            .Icon {
                color: color-mix(in srgb, var(--primary), transparent 50%);
                pointer-events: none;
            }
        }
    }

    &__PaymentInformation {
        .PaymentSelector > .card > .drawer > .card-body {
            @include branded-scroll-y($size: 0.5rem);
            max-height: 12.16rem;
        }
    }

    &__content {
        &__page {
            .checkout-grid {
                border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                border-bottom: none;

                > .AccordionDropdown {
                    > .card {
                        border: none;
                        border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%) !important;

                        > .card-header > button {
                            border: none !important;
                            @media (hover: hover) {
                                &:hover {
                                    color: currentColor !important;
                                }
                            }
                        }
                    }
                }
                > button {
                    color: $light;
                    font-family: var(--tertiary-font);
                    font-weight: 300;
                    letter-spacing: 1px;
                    border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                }
            }

            .checkout-section {
                &__title {
                    color: $light;
                    font-family: var(--secondary-font);
                    font-size: 1rem;
                    font-weight: 600;
                    letter-spacing: 0.132em;
                    text-transform: uppercase;
                }

                &__subtitle {
                    color: $light;
                    font-family: var(--secondary-font);
                    font-size: 0.875rem;
                    font-weight: 300;
                    letter-spacing: 1px;
                    margin-top: 0.5em;
                }

                p {
                    font-size: 14px;
                    font-family: var(--secondary-font);
                    font-weight: 300;
                    color: $light;
                    letter-spacing: 1px;
                }
            }

            .checkout-buttons {
                .btn > span {
                    font-family: var(--primary-font);
                    letter-spacing: 1px;
                    font-weight: bold;
                }
            }
        }
    }

    .CheckoutSummary {
        &__head {
            border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
            .title {
                font-family: var(--secondary-font);
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 0.132em;
                text-transform: uppercase;
            }
            button {
                font-size: 0.875em;
                color: var(--primary);
            }
        }

        .Tag {
            font-family: var(--primary-font);
            font-weight: bold;
            background: $dark;
            color: $light;
            border-radius: 1.25em;
            text-transform: uppercase;

            button {
                color: $light;

                @media (hover: hover) {
                    &:hover {
                        color: $danger;
                    }
                }
            }
        }

        .CartListing {
            &__price,
            &__inputs {
                border-top: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                color: var(--primary);
            }

            &__price {
                font-family: var(--secondary-font);
                letter-spacing: 1px;
                > div {
                    .label {
                        font-weight: bold;
                    }
                    .value {
                        font-weight: 300;
                    }
                    &:not(:first-child) {
                        margin-top: 1em;
                    }
                }
            }

            &__item-count {
                color: var(--primary);
                font-family: var(--secondary-font);
                font-size: 0.875em;
                font-weight: 300;
                letter-spacing: 1px;
            }

            &__footer {
                color: var(--primary);
            }

            .price-detail .label {
                font-weight: 300 !important;
            }
        }

        .recap {
            &-value {
                color: var(--primary);
            }
        }
    }
}
