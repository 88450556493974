@import '../../../../Common/src/scss/pages/_forgotPasswordPage.scss';

.forgot-password-page {
    color: var(--primary);

    h3 {
        font-weight: bold;
        font-family: var(--primary-font);
        font-size: 2.5rem;
        color: var(--primary);
        line-height: 1.875rem;
        letter-spacing: 1.5px;
        padding-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .enter_email_message {
        font-family: var(--tertiary-font);
        font-size: $font-size-sm;
        line-height: 1.25rem;
        letter-spacing: 1px;
        color: $light;
        padding-bottom: 0px;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .reset-password-page {
        form {
            @include media-breakpoint-down(sm) {
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }

    .email_input {
        padding-bottom: 1rem;

        label {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            input {
                height: 3.125rem;
            }
            width: 100%;
            padding-right: 0px;
            text-align: center;
        }
    }

    .btn-reset-email {
        font-weight: bold;
        font-family: var(--primary-font);
        font-size: $font-size-sm;
        letter-spacing: 1px;
        text-align: center;
        border: 1px solid var(--primary);
        width: 189px;
        height: 60px;

        @include media-breakpoint-down(sm) {
            height: 50px;
            width: 100%;
            text-align: center;
        }
    }
}
