﻿$filter-bg-color: var(--secondary) !default;
$filter-fg-color: $light !default;
$filter-font-family: var(--secondary-font) !default;
$filter-font-size: 14px;

.FilterDropdown {
    position: relative;
    background: transparent;
    max-height: 100%;
    overflow: visible;

    > button {
        white-space: nowrap;
        padding: 0.5em 1em;
        width: 100%;
        text-align: left;
    }

    > .facet-btn {
        color: $filter-fg-color;
    }

    button > i {
        margin-left: 0.5em;
    }

    &.expanded {
        background: $filter-bg-color;

        > button {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

            > i {
                color: black;
            }

            > span {
                color: var(--primary);
            }
        }

        > &__button-list {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
        }
    }

    &__button-list {
        position: absolute;
        z-index: 2;
        background: $filter-bg-color;
        height: auto;
        min-width: 100%;
        max-height: 20em;
        overflow-y: scroll;
        scrollbar-width: thin;
        align-content: stretch;

        label:first-child {
            margin-top: 0.5em;
        }

        label {
            margin: 1em 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 1em;

            &:not(.disabled) {
                cursor: pointer;
            }

            > span {
                color: $filter-fg-color;
                white-space: nowrap;
                user-select: none;
                font-family: $filter-font-family;
                @include font-size($filter-font-size);
            }

            > input[type='checkbox'],
            > input[type='radio'] {
                margin: 0 1em;
                background: $filter-bg-color;
                border-color: $filter-fg-color;
                height: 18px;
                min-height: 18px;
                width: 18px;
                min-width: 18px;

                &:checked::before {
                    background: $filter-fg-color;
                    height: 6px;
                    width: 6px;
                    left: calc(50% - 3px);
                    top: calc(50% - 3px);
                }
            }
        }

        button {
            &:last-child {
                margin-bottom: 0.5em;
            }

            > span {
                color: $filter-fg-color;
                white-space: nowrap;
                user-select: none;
                text-transform: none;
                font-family: $filter-font-family;
                @include font-size($filter-font-size);
            }

            &.selected > span {
                text-decoration: underline;
            }
        }
    }
}
