﻿@import '../../../../../Common/src/scss/components/react/ButtonSelector.scss';

.ButtonSelector {
    p {
        color: $light;
        font-family: var(--secondary-font);
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.0625em;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }
    .button-group {
        button.selector-btn {
            background-color: $gray-dark !important;
            border-color: color-mix(in srgb, var(--primary), transparent 50%) !important;
            color: $light !important;
            transition: 200ms background-color, 200ms border-color;
            font-weight: normal;
            &.selected {
                border-color: var(--primary) !important;
                background-color: $dark !important;
            }
            &.disabled,
            &[disabled],
            &:disabled {
                text-decoration: line-through;
                font-weight: 200;
                color: $gray-medium;
                text-decoration-color: $gray-secondary;
                opacity: 1;
                cursor: default;
            }
        }
    }
}
