﻿@import '../../../../Common/src/scss/components/_navigation_main.scss';

@keyframes linkSpinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes navIn {
    from {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(50%);
        opacity: 1;
    }
}
@keyframes navOut {
    from {
        transform: translateX(50%);
        opacity: 1;
    }
    to {
        transform: translateX(0);
        opacity: 0;
    }
}

.navbar {
    z-index: 3;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;

    a,
    button {
        pointer-events: auto;
    }

    a.active,
    a:active {
        text-decoration: underline !important;
        text-decoration-color: currentColor !important;
    }

    a.nav-link.slim {
        padding: 0 !important;
        margin: 0;
    }

    .icon-circle > svg {
        position: relative;
        border-radius: 100%;
        background: $gray-dark;
        overflow: visible;
        padding: 0.5em;
        height: 2em;
        width: 2em;
    }

    > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 1rem 9rem;

        @include media-breakpoint-down(md) {
            padding: 1rem 2rem;
        }

        .nav-links {
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            > li:not(.flex-grow-1) {
                margin-right: 1em;
                .svg-icon {
                    font-size: 2rem !important;
                }
            }
        }
    }

    #btn-nav-open,
    #btn-nav-close {
        opacity: 1;
        transition: opacity 1s ease;
        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }

    #nav-container {
        position: absolute;
        z-index: 10;
        width: 200vw;
        left: -100vw;
        padding-right: calc(100vw + 9rem);
        top: 0;
        background: var(--primary);
        color: $dark;
        opacity: 0;
        pointer-events: none;

        @include media-breakpoint-down(md) {
            bottom: 0;
            position: fixed;
            z-index: 15;
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: calc(100vw + 2rem);

            .mobile-nav-link {
                color: $gray-dark;
                padding: 0.5em 0;
                font-family: var(--secondary-font);
                font-size: 16px;
                text-transform: uppercase;
                letter-spacing: 0.133em;
                font-weight: 600;
                margin-top: 0.25em;
                text-decoration: none !important;

                &.active[href],
                &:active[href] {
                    text-decoration: underline !important;
                    text-decoration-color: $gray-dark !important;
                    font-weight: bolder;
                }
            }

            .nav-footer {
                margin-top: 1em;

                > div {
                    margin-bottom: 1em;
                    &:first-child {
                        border-top: 1px solid $dark;
                        padding-top: 1em;
                    }
                }
            }

            .social {
                a {
                    margin-right: 1em;
                    > span > i {
                        color: $gray-dark;
                        font-size: 22px !important;
                    }
                }
            }

            .terms {
                a,
                span {
                    color: $dark !important;
                    font-size: 12px;
                    font-family: var(--secondary-font);
                    font-weight: 600;
                    letter-spacing: 0.05em;
                    text-decoration: underline;
                }
            }

            .copyright {
                color: $dark;
                font-size: 14px;
                font-family: var(--secondary-font);
                font-weight: 300;
                letter-spacing: 1px;
            }
        }

        .nav-links {
            a {
                color: $dark;
                border-radius: 1.5em;
                font-size: 14px;
                font-weight: bold;
                font-family: var(--primary-font);
                text-transform: uppercase;
                padding: 1em !important;
                &.selected {
                    color: var(--primary);
                    background: $dark;
                }
            }
            i {
                color: $dark;
            }
            .nav-item-parent {
                pointer-events: none;
            }
        }

        .nav-children {
            display: none;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            max-height: none;
            width: 100%;
            color: $dark;
            &.selected {
                display: flex;
            }

            @include media-breakpoint-down(md) {
                display: flex !important;
                max-height: 0px;
                overflow: hidden;
                transition: max-height 0.3s;
            }

            h5 {
                font-size: 48px;
                margin-top: 0.5em;
                font-family: var(--primary-font);
            }

            > ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                margin-top: 0.5em;

                @include media-breakpoint-down(md) {
                    padding-bottom: 0.5em;
                    margin-bottom: 0.5em;
                    margin-top: 0;
                    border-bottom: 1px solid $dark;
                }

                > li {
                    position: relative;
                    margin: 0;
                    padding: 0;

                    @include media-breakpoint-up(lg) {
                        &:not(:first-child) {
                            border-top: 1px solid $dark;
                        }
                    }

                    a {
                        font-family: var(--primary-font);
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 2.5em;
                        height: 2.5em;
                        color: $dark;
                        text-transform: uppercase;
                        letter-spacing: 0.1em;
                        &:not(.active):not(:active) {
                            text-decoration: none !important;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 1.2rem;
                            height: 1.8em;
                            letter-spacing: 0.0575em;
                        }

                        + i {
                            display: block;
                            position: absolute;
                            right: 0.33em;
                            top: 0.33em;
                            height: 1em;
                            width: 1em;
                            opacity: 0;
                            animation: linkSpinner 0.5s linear 0s infinite;
                            transform: 0.5s opacity;
                        }

                        &.active,
                        &:active {
                            @include media-breakpoint-down(md) {
                                text-decoration: underline !important;
                            }
                            + i {
                                opacity: 1 !important;
                            }
                        }
                    }
                    @media (hover: hover) {
                        cursor: pointer;
                        &:hover > a {
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .MenuCtaPromoBlock {
            color: $gray-dark;

            a {
                text-decoration-color: $gray-dark !important;
            }
            a.cursor-pointer,
            a:not(:active):not(.active) {
                text-decoration: none !important;
            }

            @media (hover: hover) {
                .cursor-pointer:not(:disabled):not([disabled]):not(.disabled) {
                    cursor: pointer;
                }
            }

            .link-list {
                padding-top: 5.33em;

                &.collection-1 {
                    border-top: 1px solid $dark;
                    margin-top: 0.75em;
                }

                &.collection-1,
                &.collection-2 {
                    min-width: 83vw;
                    padding-left: 0px;
                    padding-top: 0.75em;
                }

                h6 {
                    font-family: var(--secondary-font);
                    font-weight: 600;
                    font-size: 16px;
                    letter-spacing: 0.133em;
                }

                ul {
                    margin: 0;
                    margin-top: 0.75em;
                    padding: 0;
                    list-style-type: none;
                    > li {
                        width: 100%;
                        position: relative;
                        margin-bottom: 0.5em;

                        &:first-child {
                            margin-top: 0.5em;
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        > a {
                            font-family: var(--secondary-font);
                            font-size: 16px;
                            font-weight: 300;
                            letter-spacing: 0.07em;
                            color: $dark;

                            @include media-breakpoint-down(md) {
                                font-size: 1.2rem;
                                height: 1.8em;
                                line-height: 1.8em;
                                letter-spacing: 0.0575em;
                                text-transform: uppercase;
                                font-family: var(--primary-font);
                                font-weight: 700;
                            }

                            + i {
                                display: block;
                                position: absolute;
                                right: 0.33em;
                                top: 0.33em;
                                height: 1em;
                                width: 1em;
                                opacity: 0;
                                animation: linkSpinner 0.5s linear 0s infinite;
                                transform: 0.5s opacity;
                            }

                            &.active,
                            &:active {
                                @include media-breakpoint-down(md) {
                                    text-decoration: underline !important;
                                }
                                + i {
                                    opacity: 1 !important;
                                }
                            }
                        }

                        @media (hover: hover) {
                            cursor: pointer;
                            &:hover > a {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            ul.cta-links {
                margin-top: 0px;
            }

            .promo-image {
                margin-bottom: 0.5em;
                font-size: 24px;
                padding-top: 1em;
                > img {
                    max-width: 100%;
                    min-width: 100%;
                    height: auto;
                }
            }
        }

        &.hide {
            animation: navOut 0.5s ease-in;
            animation-fill-mode: forwards;
        }
        &.show {
            animation: navIn 0.5s ease-out;
            animation-fill-mode: forwards;
        }
        &.enabled {
            pointer-events: auto;
        }
    }
}
