.footer__subscription {
    padding-top: 20px;
    padding-bottom: 20px;

    #mc_embed_signup {
        display: flex;
        justify-content: center;
        align-items: center;

        #mc_embed_signup_scroll {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            > input[type='email'] {
                margin-left: 15px;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }

            > label {
                margin-bottom: 0;
                margin-right: 15px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    padding-bottom: 4px;
                    margin-right: 0;
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0.5rem;
                }
            }

            > .clear {
                display: inline;
                padding: 0;
                margin: 0;

                > input[type='submit'] {
                    line-height: 18px;

                    @include media-breakpoint-down(sm) {
                        line-height: 24px;
                        border: none;
                    }

                    @include media-breakpoint-down(xs) {
                        min-width: 326px;
                        max-width: 326px;
                    }
                }
            }
        }
    }

    .MailingListSignup__message {
        background-color: $black;

        .alert-custom {
            border-color: $green !important;
            color: $white !important;
        }

        .alert-danger {
            border-color: $red-smash;
            color: $red-smash;
        }
    }

    .alert-custom {
        color: $white !important;
        background-color: $gray-dark !important;
        border-color: $yellow !important;
    }

    label {
        color: $black !important;
    }

    .subscription__form {
        input[type='email'].form-control {
            background-color: $white !important;
            color: $black !important;
        }
    }

    input[type='email'] {
        background: $white;
        border-color: $white !important;
        font-size: 1rem;
        padding: 21px 0.7em !important;
        width: 326px;

        &:-webkit-autofill {
            -webkit-text-fill-color: $black !important;
            background-color: $white !important;
            transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
        }
    }

    [type='submit'] {
        background-color: $black;
        color: $green !important;
        padding-top: 15px;
        padding-bottom: 11px;
        padding-left: 39px;
        padding-right: 39px;
        border-color: $black;
    }

    input[type='email']::placeholder {
        background-color: $white !important;
        border-color: $white;
        font-family: var(--tertiary-font);
        letter-spacing: 0.3px;
        color: $black !important;
        font-weight: normal !important;
        @include font-size(16);
    }

    @include media-breakpoint-down(sm) {
        .subheader,
        input.form-control,
        [type='submit'] {
            font-size: 16px;
        }

        label {
            margin-bottom: 0.5rem;
        }

        [type='submit'] {
            padding-top: 9px;
            padding-bottom: 5px;
            padding-left: 28px;
            padding-right: 28px;
        }

        input[type='email'] {
            padding: 6px 0.7em;
        }

        input[type='email']::placeholder {
            @include font-size(12);
        }
    }
}

.MailingListSignup {
    background-color: $primary-alpha !important;
    height: 105px !important;
}
